import React,  {useState, useEffect, useMemo} from "react";
import { logo } from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import * as PR from "../../../prime-react/index";
import "./Home.scss";
import { useDispatch, useSelector } from "react-redux";
import MenuBar from "../../../layouts/MenuBar";
import Layout from "../../../layouts/Layout";
import { getFileDetails } from "../../../services/api";
import { DateFormat, getNewsTypeLabel, newsTitleChar, truncateText } from "../../../utils/reuse";


const RegulatorHome = () => {
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth?.sessionData);
    const headers = useMemo(() => {
        return { sessionid: sessionData.sessionId };
    }, [sessionData.sessionId]);

    const navigate = useNavigate();

    const company = useSelector(state => state.auth.companyName);
    const [newsImg, setNewsImg] = useState();

    const newsData = useSelector(state => state.auth?.newsLatest);
    const newsLatest = {
        ...newsData,
        fileAttachments: newsData?.fileAttachments ? JSON.parse(newsData?.fileAttachments) : null
    };

    const newsCover = newsLatest?.fileAttachments?.filter(attachment =>
        ["image/jpeg", "image/png", "image/jpg"].includes(attachment.type)
      );
        // function to fetch the image data 
        const fetchFileDetails = (key, fileName) => {
            getFileDetails(key, headers, dispatch, response => {
                if (response.result === 'SUCCESS') {
                    const imgUrl = URL.createObjectURL(response.data);
                    setNewsImg(imgUrl);
                }
            });
        };
    
        useEffect(() => {
            if(newsCover){
                fetchFileDetails(newsCover[0]?.key, newsCover[0]?.fileName);
            }
            // eslint-disable-next-line
        },[]);

        const handleSeeMoreClick = (id) => {
            navigate(`/news-details?id=${id}`);
        };

        
    return (
        <> 
             <Layout>
                <MenuBar/>
                <section className="regulator-home-tab">
                    <div className="grid grid-nogutter mt-4 mb-4">
                        <div className="col-12 text-center">
                            <PR.Image src={logo} alt="IBIA Logo" className="logo-img" />
                        </div>
                    </div>
                    <div className="grid grid-nogutter justify-content-center mb-3">
                        <div className="col-6 text-center">
                            <p>Welcome <b>{company}!</b></p>
                        </div>
                    </div>
                    <div className="grid align-items-center text-center mt-5">
                        <div className="col-4">
                            <Link to="/reports">
                                <div className="info-box">
                                    <h2>Suspicious <br/> Betting Reports</h2>
                                    <PR.Button label='View Reports' className="view-button" />
                                </div>
                            </Link>
                        </div>
                        <div className="col-4">
                            <Link to="/alerts">
                                <div className="info-box primary-bg">
                                    <h2>View Alerts</h2>
                                </div>
                            </Link>
                        </div>
                        <div className="col-4">
                            <Link to="/news">
                                <div className="info-box">
                                    <h2>IBIA <br/> News & Updates</h2>
                                    <PR.Button label='View News' className="view-button" />
                                </div>
                            </Link>
                        </div>
                    </div>

                    {newsData && (
                        <div className="flex flex-row latest-news-section mt-2">
                            {newsImg ? 
                                    (<div className={`newsImg-outer ${newsCover?.length > 0 ? 'col-4' : 'col-0'}`}>
                                      <PR.Image src={newsImg} alt={newsLatest?.title} className="news-img" />
                                  </div>) : (
                                    <div className={`newsImg-outer ${newsCover?.length > 0 ? 'col-4' : 'col-0'}`}>
                                        <PR.Skeleton className="news-skeleton" width="100%" height="100%"></PR.Skeleton>
                                    </div>
                                    )
                                }
                                <div className={`flex flex-column justify-content-between ${newsCover?.length > 0 ? 'col-8' : 'col-12'}`}>
                                <div className="news-content">
                                        <h3 className="capitalize">{truncateText(newsLatest?.title, newsTitleChar)}</h3>
                                        <div
                                            onClick={() => handleSeeMoreClick(newsLatest?.id)}
                                            className="desc-content"
                                            dangerouslySetInnerHTML={{
                                                __html: newsLatest?.description,
                                            }}
                                        ></div>
                                </div>

                                <div className="flex justify-content-between">
                                    <ul className="flex list-none align-item-center gap-4">
                                            <li><i className="pi pi-user"></i> <span className="author">{newsLatest?.createdByUser}</span></li>
                                            <li><i className="pi pi-calendar"></i> <span>{DateFormat(newsLatest?.createdAt)}</span></li>
                                    </ul>
                                    <ul className="flex list-none align-item-center gap-4">
                                        <li><i className="pi pi-book"></i> <span>{newsLatest?.categoryName ?? getNewsTypeLabel(newsLatest?.newsType)}</span></li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                    )}
                </section>
            </Layout>
        </>
    );
};

export default RegulatorHome;