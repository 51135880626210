import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import "./CaseUpdates.scss";
import Layout from "../../layouts/Layout";
import MenuBar from "../../layouts/MenuBar";
import * as PR from "../../prime-react/index";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getCaseStatus } from "../../services/api";
import { debounce } from "lodash";
import moment from "moment";
import { RAW_FILTER_DATA } from "../common";
import DownloadPDF from "../downloadpdf/DownloadPdf";
import AddNote from "./AddNote";
import { dateTimeFormat, getSportIcon, queryLimit } from "../../utils/reuse";
import { useToast } from "../../context/ToastContext";
const CaseUpdates = () => {
  const { showToast } = useToast();
  const isMember = useSelector((state) => state.auth.role) === "member";
  const isManagedmember = useSelector((state) => state.auth.role) === "managedmember";
  const isAnalyst = useSelector((state) => state.auth.role) === "analyst";
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const showResultOnly = params.get("showResultOnly");
  const initialId = params.get("id");
  const [searchTerm, setSearchTerm] = useState(initialId || "");
  const [caseUpdates, setCaseUpdates] = useState(null);
  const [error, setError] = useState(null)
  const [noRecord, setNoRecord] = useState(false);
  const toast = useRef(null);
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.auth.sessionData);
  const headers = useMemo(() => {
    return {
      sessionid: sessionData.sessionId,
      "Content-Type": "application/json",
    };
  }, [sessionData.sessionId]);

  const isValidAlertId = (searchVal, matchValue) => {
    // Check if input starts with 'Alert-' and is followed by up to 4 digits
    if (searchVal?.toLowerCase().startsWith(matchValue)) {
        const digits = searchVal.slice(matchValue.length);
        return digits.length <= 4 && Number(digits);
    }
    return false;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((term) => {
      const matchValue = 'Alert-'.toLowerCase();
      if (term) {
        const searchParams = new URLSearchParams();
        if (!isNaN(term) && term.length <= 9) {
          searchParams.append("id", term);
        } else if (isValidAlertId(term, matchValue)) {
            searchParams.append("id", term);
        } else {
          searchParams.append("name", term);
        }
        getCaseStatus(
          searchParams.toString(),
          headers,
          dispatch,
          (response) => {
            if (response.result === "SUCCESS") {
              const responseData = response.data;
              const result = responseData?.length > 0 ? responseData : [];
              setCaseUpdates(result);
              setNoRecord(result?.length === 0);
            } else {
              setCaseUpdates(null);
              setNoRecord(true);
              const error = response?.error;
              showToast(error.severity, error?.errorMsg || error?.summary || "No record found");
            }
          }
        );
      } else {
        setCaseUpdates(null);
        setNoRecord(false);
      }
    }, 1000),
    // eslint-disable-next-line
    [headers, dispatch]
    
  );

  useEffect(() => {
    if (initialId) {
      handleSearch(initialId);
    }
  }, [initialId, handleSearch]);

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, handleSearch]);

  const getStatusLabel = (alertStatusId, caseStatusId) => {
    const alertStatus = RAW_FILTER_DATA.statusFilter?.find(
      (item) => item.id === alertStatusId
    );
    const caseStatus = RAW_FILTER_DATA.caseStatus?.find(
      (item) => item.id === caseStatusId
    );
    const currentLabel = caseStatus?.name
      ? `${alertStatus?.name}: ${caseStatus?.name}`
      : alertStatus?.name;
    const additionalStyle = alertStatus?.style || "";
    return <div className={`status ${additionalStyle}`}>{currentLabel}</div>;
  };

  const TextWithLineBreaks = ({ text }) => {
    return (
      <div>
        {text?.split(/\\n|\n/)?.map((line, index) => (
          line?.trim() ? <p key={index}>{line}</p> : <br key={index} />
        ))}
      </div>
    );
  };

  return (
    <>
      <ConditionalLayout isMember={isMember}>
        {(isMember || isManagedmember) && <MenuBar />}
        <section className="case-updates-tab">
          <div className="m-0 flex align-items-center justify-content-between relative">
            <h1>Case Updates</h1>
            <div className="export-block export-analyst">
              {isAnalyst && caseUpdates && !noRecord && (
                <DownloadPDF
                contentId="results-section"
                fileName="case-updates.pdf"
                buttonLabel="Export"
                buttonClassName="export-button"
                headerText="Case Update"
                />
              )}
            </div>
          </div>

          {!showResultOnly && (
            <div className="grid grid-nogutter relative">
              <div className="flex align-items-center search-bar">
                <span className="p-input-icon-left w-12">
                  <i className="pi pi-search" />
                  <PR.InputText
                     id="caseUpdateSearch"
                    value={searchTerm}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length <= queryLimit) {
                        setSearchTerm(value);
                      }else{
                        setError(`Keyword can be at most ${queryLimit} characters long`)
                        setTimeout(() => {
                          setError(null)
                        }, 2500)
                      }
                    }}
                    placeholder="Search by Alert ID, Legacy ID, Name..."
                    className="search-box w-12"
                  />
                </span>
              </div>
              {error && <p className="error-message mt-2">{error}</p>}
            </div>
          )}

          {!showResultOnly && !caseUpdates && !noRecord && (
            <div className="loading-section">
              <div className="card">
                <div className="small-box">
                  <div className="flex align-items-center">
                    <div className="mr-2">
                      <i className="pi pi-check-circle"></i>
                    </div>
                    <div>
                      <PR.Skeleton className="bars"></PR.Skeleton>
                      <PR.Skeleton className="bars custom-width"></PR.Skeleton>
                    </div>
                  </div>
                </div>
                <div className="big-box">
                  <div className="flex align-items-center">
                    <div className="mr-2">
                      <i className="pi pi-check-circle"></i>
                    </div>
                    <div>
                      <PR.Skeleton className="bars"></PR.Skeleton>
                      <PR.Skeleton className="bars custom-width"></PR.Skeleton>
                    </div>
                  </div>
                </div>
                <div className="small-box">
                  <div className="flex align-items-center">
                    <div className="mr-2">
                      <i className="pi pi-check-circle"></i>
                    </div>
                    <div>
                      <PR.Skeleton className="bars"></PR.Skeleton>
                      <PR.Skeleton className="bars custom-width"></PR.Skeleton>
                    </div>
                  </div>
                </div>
                <p>Search for any alert to know the progress..</p>
              </div>
            </div>
          )}
          <br />
          {!noRecord && caseUpdates && <p className="heading-p">Results:</p>}
          <div id="results-section" className="results-section">
            {noRecord && (
              <div className="flex justify-content-center no-record-section">
                <p className="no-data m-0 text-bluegray-500">No records found</p>
              </div>
            )}
            {caseUpdates &&
              caseUpdates?.map((Case, index) => (
                <div key={index} className="result-box">
                  <div className="grid grid-nogutter align-items-center justify-content-between mb-3">
                    <div className="caseIdsGrid">
                      <Link to={`/alert-details/${Case?.alertID}`} className="alert-number-span">
                        <span className="status">
                          ID&nbsp;{Case?.alertID}
                        </span>
                      </Link>
                      {Case?.legacyID &&
                        <Link to={`/alert-details/${Case?.alertID}`} className="alert-number-span">
                          <span className="status">
                            Legacy ID&nbsp; {Case?.legacyID}
                          </span>
                        </Link>
                      }
                    </div>
                    <span>
                      {getStatusLabel(Case?.alertStatus, Case?.lastCaseStatus)}
                    </span>
                  </div>
                  <div className="hr-line"></div>
                  <div className="grid mt-3">
                    <div className="col-8">
                      <div className="flex align-items-start gap-3 header-info">
                        <div className="case-update-icon">
                          {getSportIcon(Case?.sportID+"b")}
                        </div>
                        <div className="card">
                          <h2>{Case?.eventName}</h2>
                          <ul>
                            <li>Sport: {Case?.sportName}</li>
                            <li>League: {Case?.tournamentName}</li>
                            <li>
                              Alert type:{" "}
                              {RAW_FILTER_DATA.alertTypeFilter?.find(
                                (item) => item.id === Case.alertType
                              )?.name}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="hr-line"></div>
                      <div className="notes-block">
                        <div className="flex align-items-center justify-content-between">
                          <span>Notes</span>
                          {isAnalyst &&
                            <AddNote id={Case.alertID} toast={toast} refetch={handleSearch} headers={headers} label={'Add Note'} icon={'pi pi-pencil'} />
                          }
                        </div>
                        <div className="mt-4">
                          {Case?.notes?.sort((a,b)=>moment(b.createdAt)-moment(a.createdAt))?.map((el,i)=><div key={i}><div className="note-message mb-3" key={i}><TextWithLineBreaks text={el?.notes} /></div></div>)}
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="activity-section">
                        <p>Activity</p>
                        <div className="hr-line"></div>
                        {Case?.activity?.map((activity, index) => (
                          <ul
                            key={index}
                            className="flex align-items-center justify-content-between list-none"
                          >
                            <li>
                              <span>
                                {
                                  RAW_FILTER_DATA.caseStatus?.find(
                                    (item) =>
                                      item.id === activity?.caseStatus
                                  )?.name
                                }
                              </span>
                            </li>
                            <li>
                              {dateTimeFormat(activity?.createdAt)}
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </section>
      </ConditionalLayout>
    </>
  );
};

export default CaseUpdates;

const ConditionalLayout = ({ children, isMember }) => {
  return (
    <>
      {isMember ? (
        <Layout>{children}</Layout>
      ) : (
        <Layout backBtn={"home"} backBtnLabel="Home">{children}</Layout>
      )}
    </>
  );
};
