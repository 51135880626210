import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as PR from "../../prime-react/index";
import { chatIcon, filterIcon } from "../../assets/images/index";
import AppliedFilter from './AppliedFilter';
import SearchFilter from './SearchFilter';
import { getAlertConversation, updateAlertConversation, createAlertConversation, getAlertResponses, updateAlertLasttRead, getAlertDetails } from '../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { RAW_FILTER_DATA } from '../../shared/common';
import { chatCount, cleanData, DateFormat, dateTimeFormat, formatTime, getSportIcon, rowsPerPage } from '../../utils/reuse';
import { authActions } from '../../store/auth';
import ImageDisplay from '../ImageDisplay';
import AlertsSearchId from './AlertSearchId';
import { isEmpty, some } from 'lodash';
import { useToast } from '../../context/ToastContext';

const AlertsGrid = ({activeIndex, totalCount, offset, limit, sortValue, sortOrder, tabChangeHandler, getSelectedFilters, selectedFilters,handlePageChange, exportCSV, exportLoading, loading}) => {
    const dispatch = useDispatch();
    const { showToast } = useToast();
    const alerts = useSelector(state => state.auth.alerts);
    const sortKey = useRef({field:'createdOnRaw',value:!!sortValue ? 1 : -1})
    const pathname = window.location.pathname;
    const sessionData = useSelector(state => state.auth.sessionData);
    const groupPermissions = sessionData?.groupPermission;
    const headers = useMemo(() => {
        return { sessionid: sessionData.sessionId };
    }, [sessionData.sessionId]);
    const groupID = sessionData.groupID;
    const isMember = useSelector(state => state.auth.role) === 'member';
    const isAnalyst = useSelector(state => state.auth.role) === 'analyst';
    const isManagedMember = useSelector(state => state.auth.role) === 'managedmember';
    const [respondedVisible, setRespondedVisible] = useState(false);
    const [notRespondedVisible, setNotRespondedVisible] = useState(false);
    const [suspiciousVisible, setSuspiciousVisible] = useState(false);

    const [chatVisible, setChatVisible] = useState(false);
    const [filterDialogVisible, setFilterDialogVisible] = useState(false);
    const [filterVisible, setFilterVisible] = useState(true);
    const userName = sessionData.companyName
    const [message, setMessage] = useState('')
    const [isAlertsUpdated, setIsAlertsUpdated] = useState(false);
    const [chatData, setChatData] = useState([]);
    const [chatParticipants, setChatParticipants] = useState([])
    const [chatInfo,setChatInfo] = useState({
        alertID:'',
        alertName:'',
        groupID:'',
        conversationID:''
    });
    const [chatLoading, setChatLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();
    const gotoAlertDetails=(alertID)=>{
        getAlertDetails(alertID, headers, dispatch, ({result, error, data})=>{
            const alertDetailsData = {result, error, data};
            if(result==='SUCCESS' && (data && data?.alertID)) {
                navigate("/alert-details/" + alertID, {state: {alertDetailsData}});
            } else {
                showToast("error", "Alert ID not found");
            }
        });
    }
    const gotoAlertDetailsResponses=(alertID, responseID)=>{
        navigate("/alert-details/" + alertID, {state:{responseID}});
    }
    const setFilters = (data) => {
        getSelectedFilters(data)
        tabChangeHandler(activeIndex,data)
    }
    const filterHideShow = () => {
        setFilterVisible(!filterVisible);
    };
    const [isChatPopupOpen, setChatPopupOpen] = useState(false)
    const updateChat = (chatID,content) => {
        setChatLoading(true)
        updateAlertConversation(chatID,content,headers, dispatch, response => {
            getChatConversation(chatInfo.alertID, chatInfo.groupID)
            setChatLoading(false)
        })
    }

    const createChat = (alertID,groupID,content) => {
        setChatLoading(true)
        createAlertConversation(alertID,groupID,content,headers, dispatch, response => {
            getChatConversation(chatInfo.alertID, chatInfo.groupID)
            setChatLoading(false)
        })
    }

    const getChatConversation = (id, groupID) => {
        setChatLoading(true)
        getAlertConversation(id, groupID, headers, dispatch, response => {
            setChatPopupOpen(true)
            if(response.data?.messages){
                setChatData(response.data.messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)))
                setChatInfo(prev => ({
                    ...prev,
                    conversationID:response.data.conversationID
                }))
                setChatParticipants(response.data.participants);

                // to update chatRead only when readAt is null for last sender's message
                const lastSenderMessage = response.data.messages.filter(message => message.senderID !== sessionData.groupID).pop();
                if (lastSenderMessage && !lastSenderMessage.readAt) {
                    setIsAlertsUpdated(true);
                }

            }else{
                setChatData([])
            }
            setChatLoading(false);
        })
    }

    const updateChatRead = (chatID, isRead) => {
        if (chatID) {
            updateAlertLasttRead(chatID, isRead, headers, dispatch, response => {
                if(response.result === 'SUCCESS'){
                    const responseData = response.data;
                    changeActiveConversation(chatInfo?.alertID, responseData?.activeConversation);
                }
            })
        }
    }

    const currentConversationID = useMemo(() => chatInfo?.conversationID, [chatInfo]);
    useEffect(() => {
        if (currentConversationID && isAlertsUpdated) {
            // updateChatRead(currentConversationID, true); 
            updateChatRead(currentConversationID, {isRead: true, includeActiveConversation: true}); 
            setIsAlertsUpdated(false);
        }
    // eslint-disable-next-line
    },[isAlertsUpdated])


    const getChatUserName = (senderId) => {
        const userName = chatParticipants.filter(user => user.ID=== senderId).map(user => user.name)
        return userName[0]
    }
    const getChatUserImage = (senderId) => {
        const userImage = chatParticipants.filter(user => user.ID === senderId)?.map(user => user.image);
        return userImage[0];
    }

    const setResponseCount = (id, responseCount) => {
        const alert = alerts.find(alert => alert.alertID === id)
        const updatedAlert = { ...alert, ...responseCount }
        const updatedAlerts = alerts.map(alert => alert.alertID === id ? updatedAlert:alert)

        dispatch(authActions.updateAlertsList(updatedAlerts))
    }

    const changeActiveConversation = (id, isActive) => {
        const alert = alerts.find(alert => alert.alertID === id)
        const updatedAlert = { ...alert, activeConversation: isActive };
        const updatedAlerts = alerts.map(alert => alert.alertID === id ? updatedAlert : alert);

        dispatch(authActions.updateAlertsList(updatedAlerts));
    }

    const [responsesPopupLoading, setResponsesPopupLoading] = useState(false)
    const getAlertResponsesHandler = (id) => {
        setResponsesPopupLoading(true)
        getAlertResponses(id, headers, dispatch, response => {
            if (response.result === 'SUCCESS' && response.data) { 
                let data = {alertID: id}
                const alertResponses = response.data?.sort((a, b) => a.respondingGroup.localeCompare(b.respondingGroup));
                
                const respondedInfo = alertResponses.filter(response => response.responseID)
                const notRespondedInfo = alertResponses.filter(response => !response.responseID)

                data["respondedInfo"] = respondedInfo
                data["respondedCount"] = respondedInfo.length

                const claimedSuspicious = respondedInfo.filter(response => response.responseType === "offered and suspicious")
                
                data["suspiciousInfo"] = claimedSuspicious
                data["suspiciousCount"] = claimedSuspicious.length
                
                data["notRespondedInfo"] = notRespondedInfo
                data["notRespondedCount"] = notRespondedInfo.length

                setResponseCount(id,{
                    responded: data["respondedCount"],
                    notResponded: data["notRespondedCount"],
                    suspicious: data["suspiciousCount"],
                })

                setSelectedRowData(data)
                setResponsesPopupLoading(false)
            }else{
                setResponsesPopupLoading(false)
            }
        })
    }

    const eventID = (rowData) => {
        return (
            <div className="flex align-items-center gap-3">
                <div className='sports-icon'>{getSportIcon(rowData?.sportID + 'b')}</div>
                <div className="event-column">
                    <p>{rowData.alertName}</p>
                    <span>ID: {rowData.alertID}</span>
                </div>
            </div>
        );
    };

    const createdAtDateDisplay = (rowData) => {
        return (
            <div className="flex align-items-center gap-3">
                <div className="event-column">
                    <p>{DateFormat(rowData.createdAt)}</p>
                    <span>{formatTime(rowData.createdAt)}</span>
                </div>
            </div>
        );
    };
    const [rawFilterData] = useState(RAW_FILTER_DATA);

    const status = (rowData) => {
        const alertStatus = rawFilterData.statusFilter?.find(item => item.id === rowData.alertStatus)
        const caseStatus = rawFilterData.caseStatus?.find(item => item.id === rowData.caseStatus)

        const currentLabel = caseStatus?.name ?`${alertStatus?.name}: ${caseStatus?.name}`: alertStatus?.name
        const additionalStyle = alertStatus?.style || '';

        return (
            <div className={`status ${additionalStyle}`}>
                {currentLabel}
            </div>
        )
    };

    const alertTypeHandler = (row) => {
      return (
        <div>{rawFilterData.alertTypeFilter.find(item => item.id === row.alertType)?.name}</div>
      )
    };

    const [selectedRowData, setSelectedRowData] = useState({});
    const response = (rowData) => {
        const disableStyle = rowData.responded>0 ?'':'disableStyle'
        return (
            <>
                <div className="flex align-items-center gap-2">
                    <div className={`response ${disableStyle}`} onClick={() => {
                            if(rowData.responded>0){
                                setRespondedVisible(true)
                                getAlertResponsesHandler(rowData.alertID)
                            }
                        }}
                    >
                        <i className="pi pi-check"></i> {rowData.responded}
                    </div>
                    <div className={`response not-respond ${disableStyle}`} onClick={() => {
                            if(rowData.responded>0 && rowData.notResponded>0){
                                setNotRespondedVisible(true)
                                getAlertResponsesHandler(rowData.alertID)
                            }
                        }}
                    >
                        <i className="pi pi-times"></i> <span className='text-blur'>{rowData.notResponded}</span>
                    </div>
                    <div className={`response suspicious ${disableStyle}`} onClick={() => {
                            if(rowData.responded>0 && rowData.suspicious>0){
                                setSuspiciousVisible(true)
                                getAlertResponsesHandler(rowData.alertID)
                            }
                        }}
                    >
                        <i className="pi pi-info-circle"></i> <span className='text-blur'>{rowData.suspicious}</span>
                    </div>
                    {!isManagedMember && <div
                        className={`response chat
                            ${(isAnalyst && sessionData.groupID === rowData.createdByGroupID) ?  rowData?.activeConversation ? 'non-clickable' : 'disabled' : ''}
                            ${isMember && (!rowData.conversationExists) ? 'disabled' : 'clickable'}
                            ${rowData?.activeConversation ? 'active' : ''}
                        `}
                    >
                        <PR.Image src={chatIcon} alt="Chat ID" className="icon" onClick={() => {
                            setChatVisible(true)
                            // setChatData(rowData?.ibiaChats || [])

                            const id = isMember ? groupID : rowData.createdByGroupID
                            getChatConversation(rowData?.alertID, id)
                            setChatInfo(prev => ({
                                ...prev,
                                groupID:id,
                                alertID:rowData?.alertID,
                                alertName: rowData?.alertName
                            }))
                        }}/>
                    </div>}
                </div>
            </>
        );
    };
    const action = (rowData) => {
        return (
            <div className="flex flex-wrap">
                <PR.Button label="View Details" type='submit' severity="secondary" outlined className="view-details-button" onClick={() => gotoAlertDetails(rowData.alertID)} />
                {(rowData?.alertStatus === 'open' || rowData?.alertStatus === 'evaluating') && (rowData.responseRequired || activeIndex === 2) && <span className="response-span flex align-items-center gap-1"><i className="pi pi-info-circle"></i> Response required</span>}
            </div>
        );
    };


    const header = (
        <>
            <div className="status-filter-section flex gap-2">
                <AlertsSearchId headers={headers} goTo={gotoAlertDetails} disabledField={loading || exportLoading}/>
                <PR.Button className={cleanData(selectedFilters) ? 'search-filters-button search-filter-applied' : 'search-filters-button'} iconPos='left' outlined onClick={() => setFilterDialogVisible(true)} disabled={loading || exportLoading}>
                    <PR.Image src={filterIcon} alt="Filter Icon" className="icon" />
                    <span className="label">Search Filters</span>
                </PR.Button> 
                {((groupPermissions?.includes('export') && activeIndex === 0) || (isAnalyst && pathname === "/home")) && 
                <PR.Button className="search-filters-button export-icon" iconPos='left' outlined onClick={exportCSV} disabled={loading || exportLoading}>
                    <i className='pi pi-file-export export-icon'></i>
                    <span className="label">Export</span>
                </PR.Button>}
            </div>
            {(some(selectedFilters, v =>!isEmpty(v))) &&
                <AppliedFilter selectedFilters={selectedFilters} setFilters={setFilters} filterVisible={filterVisible} filterHideShow={filterHideShow}/>
            }
        </>
    );
    const respondedFooterContent = (
        <div>
            <PR.Button 
                label="Close" 
                onClick={() => {
                    setRespondedVisible(false)
                    setSelectedRowData({})
                    setRespondedQuery('')
                }} 
                outlined 
                className="logout-btns" />
        </div>
    );

    const notRespondedFooterContent = (
        <div>
            <PR.Button 
                label="Close" 
                onClick={() => {
                    setNotRespondedVisible(false)
                    setSelectedRowData({})
                    setNotRespondedQuery('')
                }} 
                outlined 
                className="logout-btns" />
        </div>
    );

    const suspiciousFooterContent = (
        <div>
            <PR.Button 
                label="Close"
                onClick={() => {
                    setSuspiciousVisible(false)
                    setSelectedRowData({})
                    setSuspiciousQuery('')
                }} 
                outlined className="logout-btns" />
        </div>
    );

    const messagesEndRef = useRef(null);
    const isDisabled = !(message?.trim());
    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    useEffect(() => {
        if (chatVisible) {
            setTimeout(scrollToBottom, 10);
        }
    }, [chatVisible, chatData]);

    const chatFooterContent = (
        <div>
            <div className='align-items-center justify-content-between'>
                <span className="ask-field">
                    <PR.InputTextarea
                        placeholder="Message to send..."
                        value={message}
                        onChange={(e) => {
                            if (e.target.value.length > chatCount) {
                                setMessage(e.target.value.substring(0, chatCount));
                                setErrorMessage(`Message should be ${chatCount} characters or less.`);
                            } else {
                                setMessage(e.target.value);
                                setErrorMessage("");
                            }
                        }}
                        onPaste={(e) => {
                            e.preventDefault();
                            const pastedData = e.clipboardData.getData('text');
                            const maxCharsToAdd = chatCount - message?.length;
                            const textarea = e.target;
                            const cursorPosition = textarea.selectionStart;
                            const newMessage = 
                                message.substring(0, cursorPosition) + 
                                pastedData.substring(0, maxCharsToAdd) + 
                                message.substring(cursorPosition);
                            setMessage(newMessage.substring(0, chatCount));
                            // Set cursor position after pasting
                            setTimeout(() => {
                                textarea.setSelectionRange(cursorPosition + pastedData.slice(0, maxCharsToAdd).length, cursorPosition + pastedData.slice(0, maxCharsToAdd).length);
                            }, 0);
                                                if (newMessage.length > chatCount) {
                                setErrorMessage(`Message should be ${chatCount} characters or less.`);
                            }
                        }}
                    />
                    <div className="descriptioncharLimit mt-1">
                        {chatCount - message?.length}/{chatCount} characters left
                    </div>
                    <span style={{textAlign:"left"}}>{errorMessage && <div className="error-message">{errorMessage}</div>}</span>
                </span>
                <PR.Button label="Send Message" onClick={() => {
                    if(chatData?.length===0){
                        createChat(chatInfo.alertID,chatInfo.groupID,message)
                    }else{
                        updateChat(chatInfo.conversationID, message)
                    }
                    setMessage('');
                    setErrorMessage('')
                    }} 
                    outlined className="logout-btns send-message mt-3 mr-0" 
                    disabled ={isDisabled}
                />
            </div>
        </div>
        
    );

    const [respondedQuery, setRespondedQuery] = useState('');

    const filteredRespondedInfo = selectedRowData?.respondedInfo?.filter(company =>
        company.respondingGroup.toLowerCase().includes(respondedQuery.toLowerCase())
    );

    const [notRespondedQuery, setNotRespondedQuery] = useState('');

    const filteredNotRespondedInfo = selectedRowData?.notRespondedInfo?.filter(company =>
        company.respondingGroup.toLowerCase().includes(notRespondedQuery.toLowerCase())
    );

    const [suspiciousQuery, setSuspiciousQuery] = useState('');

    const filteredSuspiciousInfo = selectedRowData?.suspiciousInfo?.filter(company =>
        company.respondingGroup.toLowerCase().includes(suspiciousQuery.toLowerCase())
    );

    const showCompanyColumn = () => {
        return (
            (activeIndex!==3 && (pathname ==='/alerts') && (isMember || isManagedMember) ) ||
            (isAnalyst)
        )
    }

    const getReference = (count) => {
        return count > 1 ? 'members':'member'
    }
    const sortAlertType = (v) => {
        const value = sortKey.current.value===1?-1:1
        sortKey.current={field:'alertType', value}
        let arr = [...alerts]
        arr.sort((a,b)=>  v ===1 ? a.alertType.localeCompare(b.alertType) : b.alertType.localeCompare(a.alertType) )
        dispatch(authActions.updateAlertsList(arr));
    }
    return (
        <>
            <div className="grid grid-nogutter">
                <div className="card col-12 relative">
                    <PR.DataTable 
                        value={alerts} 
                        stripedRows 
                        className="data-table"
                        paginator
                        lazy
                        onPage={handlePageChange}
                        first={offset}
                        totalRecords={totalCount}
                        rows={limit}
                        rowsPerPageOptions={rowsPerPage}
                        header={header} 
                        tableStyle={{ minWidth: '50rem' }}
                        paginatorTemplate={{layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown', 'PageLinks': (options) => {
                            if(options.currentPage+2 >= options.page && options.currentPage-2 <= options.page )return options.element
                            else if (0 === options.page) return options.element
                            else if(3 >= options.page) return <>.</>
                            else if (options.totalPages-1 === options.page) return options.element
                            else if(options.totalPages-4 <= options.page) return <>.</>
                        } }}
                        pageLinkSize={Math.ceil(totalCount/limit)}
                        sortField={sortKey.current.field}
                        sortOrder={sortKey.current.value}
                        onSort={({sortField, sortOrder:v})=>{sortField==="createdOnRaw"&& sortOrder(); sortField === 'alertType' && sortAlertType(v) }}
                    >
                        <PR.Column body={eventID} header="Event" style={{width:"30%"}}></PR.Column>
                        <PR.Column 
                            body={createdAtDateDisplay}
                            header="Created" 
                            sortable
                            sortField="createdOnRaw"
                            style={{width:"10%"}}
                        ></PR.Column>
                        <PR.Column body={alertTypeHandler} header="Type" sortable sortField="alertType"></PR.Column>
                        {showCompanyColumn() && <PR.Column field="createdByGroupName" header="Company" className='capitalize'></PR.Column>}
                        <PR.Column body={status} header="Status"></PR.Column>
                        <PR.Column body={response} header="Response"></PR.Column>
                        <PR.Column body={action} header="Action" className="last-column"></PR.Column>
                    </PR.DataTable>
                </div>
            </div>
            {/* Members Responded Dialog */}
            {responsesPopupLoading && respondedVisible?(
                <div className="loading chat-loading">
                    <i className="pi pi-spin pi-spinner"></i>
                </div>
            ):(<PR.Dialog visible={respondedVisible} footer={respondedFooterContent} className="logout-dialog response-dialog" onHide={() => setRespondedVisible(false)} closeOnEscape="true" blockScroll="true">
                {/****  Members Responded ****/}
                <div className="dialog-header">
                    <div className='flex align-items-center justify-content-between mb-5'>
                        <h2>{selectedRowData?.respondedCount} {getReference(selectedRowData?.respondedCount)} responded</h2>
                        <span className='suspicious-span'>{selectedRowData?.suspiciousCount} claimed Suspicious</span>
                    </div>
                    <span className="p-input-icon-left search-field">
                        <i className="pi pi-search" />
                        <PR.InputText
                            placeholder="Search"
                            value={respondedQuery} 
                            onChange={(e) => setRespondedQuery(e.target.value)} 
                        />
                    </span>
                </div>
                <div className="dialog-body-section">

                    {filteredRespondedInfo?.map((company, index) => {
                        return (
                            <div key={index} className="flex align-items-center member-response-list justify-content-between">
                                <div className='left'>
                                    <ul className='flex list-none align-items-center gap-2'>
                                        <li className='flex align-items-center gap-2'>
                                            <ImageDisplay profileImage={company?.groupImage} type="group"/> <span>{company.respondingGroup}</span></li>
                                        <li>|</li>
                                        <li>{dateTimeFormat(company.createdAt)}</li>
                                    </ul>
                                </div>
                                <div className='right flex align-items-center'>
                                    <span className={(company.responseType === 'offered and suspicious')?'suspicious':null}>{company.responseType}</span> <i className="pi pi-chevron-right" onClick={() => gotoAlertDetailsResponses(selectedRowData?.alertID,company?.responseID )}></i>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </PR.Dialog>)}

            {/* Members Not Responded Dialog */}
            {responsesPopupLoading && notRespondedVisible?(
                <div className="loading chat-loading">
                    <i className="pi pi-spin pi-spinner"></i>
                </div>               
            ):(<PR.Dialog visible={notRespondedVisible} footer={notRespondedFooterContent} className="logout-dialog response-dialog" onHide={() => setNotRespondedVisible(false)} closeOnEscape="true" blockScroll="true">

                {/* Members Not Responded  */}
                <div className="dialog-header">
                    <div className='flex align-items-center justify-content-between mb-5'>
                        <h2>{selectedRowData?.notRespondedCount} {getReference(selectedRowData?.notRespondedCount)} not responded</h2>
                    </div>
                    <span className="p-input-icon-left search-field">
                        <i className="pi pi-search" />
                        <PR.InputText 
                            placeholder="Search"
                            value={notRespondedQuery} 
                            onChange={(e) => setNotRespondedQuery(e.target.value)} 
                        />
                    </span>
                </div>
                <div className="dialog-body-section">
                    {filteredNotRespondedInfo?.map((company, index) => {
                        return (
                            <div key={index} className='not-responded-list'>
                                <ImageDisplay profileImage={company?.groupImage} type="group"/> <span>{company.respondingGroup}</span>
                            </div>
                        )
                    })}
                </div> 
            </PR.Dialog>)}

            {/* Members Claimed Suspicious Dialog */}
            {responsesPopupLoading && suspiciousVisible?(
                <div className="loading chat-loading">
                    <i className="pi pi-spin pi-spinner"></i>
                </div>
            ):(<PR.Dialog visible={suspiciousVisible} footer={suspiciousFooterContent} onHide={() => setSuspiciousVisible(false)} className="logout-dialog response-dialog" closeOnEscape="true" blockScroll="true">
                {/* Members Claimed Suspicious  */}
                <div className="dialog-header">
                    <div className='flex align-items-center justify-content-between mb-5'>
                        <h2>{selectedRowData?.suspiciousCount} {getReference(selectedRowData?.suspiciousCount)} claimed Suspicious</h2>
                    </div>
                    <span className="p-input-icon-left search-field">
                        <i className="pi pi-search" />
                        <PR.InputText 
                            placeholder="Search"
                            value={suspiciousQuery}
                            onChange={(e) => setSuspiciousQuery(e.target.value)} 
                        />
                    </span>
                </div>
                <div className="dialog-body-section">
                    {filteredSuspiciousInfo?.map((company, index) => {
                        return (
                            <div key={index} className="flex align-items-center member-response-list justify-content-between">
                                <div className='left'>
                                    <ul className='flex list-none align-items-center gap-2'>
                                        <li className='flex align-items-center gap-2'>
                                        <ImageDisplay profileImage={company?.groupImage} type="group"/> <span>{company.respondingGroup}</span></li>
                                        <li>|</li>
                                        <li>{dateTimeFormat(company.createdAt)}</li>
                                    </ul>
                                </div>
                                <div className='right flex align-items-center'>
                                    <span className='suspicious'>Offered and Suspicious</span> <i className="pi pi-chevron-right" onClick={() => gotoAlertDetailsResponses(selectedRowData?.alertID,company?.responseID )}></i>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </PR.Dialog>)}
            {/* Conversation Dialog */}
            {chatLoading && !isChatPopupOpen?(
                <div className="loading chat-loading">
                    <i className="pi pi-spin pi-spinner"></i>
                </div>
            ):(<PR.Dialog visible={chatVisible} 
                footer={chatFooterContent}
                onHide={() => {
                    setChatVisible(false)
                    setMessage('')
                    setErrorMessage('')
                    setChatPopupOpen(false)
                    setChatInfo({
                        alertID:'',
                        alertName:'',
                        groupID:'',
                        conversationID:''
                    })
                }} 
                className="logout-dialog response-dialog chat-dialog"
                closeOnEscape="true" 
                blockScroll="true">
                <div className="dialog-header">
                    <div className='flex align-items-center gap-2'>
                        <ImageDisplay profileImage={sessionData?.groupImage} type='group'/>
                        <div className='chat-header'>
                            <div className=''>Chat about Alert ID: <span className="capitalize">{chatInfo.alertID}</span></div>
                            <span className='alert-name'>{chatInfo.alertName}</span>
                        </div>
                    </div>
                </div>
                {chatData?.length === 0
                    ? <p className='no-message-p'>No messages yet.</p>
                    :(
                        <div className="dialog-body-section">
                            {chatData.map((item, index) => {
                                const rendermessage = getChatUserName(item.senderID) !== userName?(
                                    <div className='chat-block-received' key={index}>
                                        <div className=' flex align-items-start'>
                                            <div className='mr-3'>
                                                <ImageDisplay profileImage={getChatUserImage(item.senderID)} type="user"/>
                                            </div>
                                            <div className='message-box'>
                                                <div className='message'>
                                                    <p>{item.content}</p>
                                                </div>
                                                <div className='time'>
                                                    <span>{dateTimeFormat(item.createdAt)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>):(
                                    <div className='chat-block-sent flex1 align-items-start' key={index}>
                                        <div className='message-box'>
                                            <div className='message'>
                                                <p>{item.content}</p>
                                            </div>
                                            <div className='time text-right'>
                                                <span>{dateTimeFormat(item.createdAt)}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                                return <div key={index}>{rendermessage}</div>
                            })}
                            <div ref={messagesEndRef} />
                        </div>
                    )
                }
            </PR.Dialog>)}
            <AlertsSearchFilter 
                filterDialogVisible={filterDialogVisible}
                setFilterDialogVisible={setFilterDialogVisible}
                setFilters={setFilters}
                alertsActiveIndex={activeIndex}
                selectedFilters={selectedFilters}
            />
        </>
    );
};
export const AlertsSearchFilter = ({alertsActiveIndex, filterDialogVisible,setFilterDialogVisible,setFilters,selectedFilters}) => {
    const [rawFilterData] = useState(RAW_FILTER_DATA);
    return (
        <>
            {/* Filter Modal Dialog */}
            <PR.Dialog visible={filterDialogVisible} onHide={() => setFilterDialogVisible(false)} className="response-dialog filter-dialog" closeOnEscape="true" blockScroll="true">
                <SearchFilter
                    setFilterDialogVisible={setFilterDialogVisible}
                    setFilters={setFilters}
                    alertsActiveIndex={alertsActiveIndex}
                    staticOptionData={rawFilterData}
                    selectedFilters={selectedFilters}
                />
            </PR.Dialog>
        </>
    )
}
export default AlertsGrid;
