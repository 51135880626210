import React, { useState, useEffect, useCallback, useMemo } from "react";
import { logo, Avatar } from "../../../assets/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as PR from "../../../prime-react/index";
import "./Home.scss";
import { useDispatch, useSelector } from "react-redux";
import MenuBar from "../../../layouts/MenuBar";
import Layout from "../../../layouts/Layout";
import { getAlertsCount, getFileDetails } from "../../../services/api";
import { authActions } from "../../../store/auth";
import { DateFormat, getNewsTypeLabel, newsTitleChar, truncateText } from "../../../utils/reuse";

const Home = ({ handleTabChange }) => {
    const company = useSelector(state => state.auth.companyName);
    const alertCount = useSelector(state => state.auth.alertCount);
    const conversationCount = useSelector(state => state.auth.conversationCount);
    const newsData = useSelector(state => state.auth.newsLatest);
    const isManagedMember = useSelector(state => state.auth.role) === 'managedmember';
    const {state} = useLocation()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const newsLatest = {
        ...newsData,
        fileAttachments: newsData?.fileAttachments ? JSON.parse(newsData?.fileAttachments) : null
    };
    const newsCover = newsLatest?.fileAttachments?.filter(attachment =>
        ["image/jpeg", "image/png", "image/jpg"].includes(attachment.type) && attachment.attachmentType === 'coverImage'
    );

    const [newsImg, setNewsImg] = useState(newsCover?.length > 0 ? null : Avatar);
    const sessionData = useSelector(state => state.auth.sessionData);

    const headers = useMemo(() => {
        return { sessionid: sessionData.sessionId };
    }, [sessionData.sessionId]);

    const getCountHandler = useCallback(() => {
        const obj = { type: "rr,nm" };
        getAlertsCount(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data;
                const newAlertCount = responseData?.responseRequired || 0;
                const newConversationCount = responseData?.newMessages || 0;
                dispatch(authActions.updateAlertCount({ alertCount: newAlertCount }));
                dispatch(authActions.updateConversationCount({ conversationCount: newConversationCount }));
            }
        });
    }, [dispatch, headers]);

    useEffect(() => {
        getCountHandler();
        // eslint-disable-next-line
    }, []);
    
    useEffect(()=>{
        if(state?.source ==='fromLogin' && alertCount > 0){
            handleTabChange(2);
        }

    },[handleTabChange,navigate, state, alertCount]);
    
    const fetchFileDetails = (key, fileName) => {
        getFileDetails(key, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const imgUrl = URL.createObjectURL(response.data);
                setNewsImg(imgUrl);
            }
        });
    };

    useEffect(() => {
        if (newsCover?.length > 0) {
            fetchFileDetails(newsCover[0]?.key, newsCover[0]?.fileName);
        }
        // eslint-disable-next-line
    }, []);

    const handleSeeMoreClick = (id) => {
        navigate(`/news-details?id=${id}`);
    };

    return (
        <>
            <Layout>
                <MenuBar />
                <section className="home-tab">
                    <div className="grid grid-nogutter mt-4 mb-4">
                        <div className="col-12 text-center">
                            <PR.Image src={logo} alt="IBIA Logo" className="logo-img" />
                        </div>
                    </div>
                    <div className="grid grid-nogutter justify-content-center mb-3">
                        <div className="col-6 text-center">
                            <p>Welcome <b>{company}!</b></p>
                        </div>
                    </div>
                    {!isManagedMember && <div className="grid align-items-center text-center mt-5">
                        <div className="col-4">
                            <Link to="/alerts" onClick={() => handleTabChange(2)}>
                                <div className="info-box">
                                    <h2>{alertCount} Alerts</h2>
                                    <p>Require Response</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-4">
                            <Link to="/create-alert">
                                <div className="info-box primary-bg">
                                    <h2><span className="pi pi-plus"></span></h2>
                                    <p>Create Alert</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-4">
                            <Link to="/alerts" onClick={() => handleTabChange(0)}>
                                <div className="info-box">
                                    <h2>{conversationCount} New</h2>
                                    <p>Chat Messages</p>
                                </div>
                            </Link>
                        </div>
                    </div>}
                    {newsData && (
                        <div className="flex flex-row latest-news-section mt-2">
                                {newsImg ? 
                                    (<div className={`newsImg-outer ${newsCover?.length > 0 ? 'col-4' : 'col-0'}`}>
                                      <PR.Image src={newsImg} alt={newsLatest?.title} className="news-img" />
                                  </div>) : (
                                    <div className={`newsImg-outer ${newsCover?.length > 0 ? 'col-4' : 'col-0'}`}>
                                        <PR.Skeleton className="news-skeleton" width="100%" height="100%"></PR.Skeleton>
                                    </div>
                                    )
                                }
                                <div className={`flex flex-column justify-content-between ${newsCover?.length > 0 ? 'col-8' : 'col-12'}`}>
                                    <div className="news-content">
                                        <h3 className="capitalize">{truncateText(newsLatest?.title, newsTitleChar)}</h3>
                                        <div
                                            onClick={() => handleSeeMoreClick(newsLatest?.id)}
                                            className="desc-content"
                                            dangerouslySetInnerHTML={{
                                                __html: newsLatest?.description,
                                            }}
                                        ></div>
                                    </div>
                                    <div className="flex justify-content-between">
                                        <ul className="flex list-none align-item-center gap-4">
                                            <li><i className="pi pi-user"></i> <span className="author">{newsLatest?.createdByUser}</span></li>
                                            <li><i className="pi pi-calendar"></i> <span>{DateFormat(newsLatest?.createdAt)}</span></li>
                                        </ul>
                                        {newsLatest?.categoryName ? (
                                            <ul className="flex list-none align-item-center gap-4">
                                                <li><i className="pi pi-book"></i> <span>{newsLatest?.categoryName}</span></li>
                                            </ul>
                                        ): (
                                            <ul className="flex list-none align-item-center gap-4">
                                                {newsLatest?.newsType ? (
                                                <li><i className="pi pi-book"></i> 
                                                    <span>{newsLatest?.categoryName ?? getNewsTypeLabel(newsLatest?.newsType)}</span>
                                                </li>
                                                ) :null}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                        </div>
                    )}
                </section>
            </Layout>
        </>
    );
};

export default Home;
