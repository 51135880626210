import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../layouts/Layout";
import * as PR from "../../../prime-react/index";
import { getNewsDetails, getFileDetails } from '../../../services/api';
import MostViewed from "../../../shared/MostViewed/MostViewed";
import TopCategories from "../../../shared/TopCategories/TopCategories";
import { DateFormat } from "../../../utils/reuse";
import dbData from "../../../assets/data/db.json"
import {
  slide1,
  slide2,
  news1,
  news2,
  news3,
  news4,
  news5,
  news6,
  news7,
  newView1,
  newView2,
  Avatar,
  newView3,
  newView4,
  newView5,
  newView6,
} from "../../../assets/images/index";
import { useToast } from "../../../context/ToastContext";
const SportingSanctionDetail = () => {
  const { showToast } = useToast();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get('id');
  const [newsDetail, setNewsDetail] = useState(null);
  const [mostViewedData, setMostViewedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [attachmentImage, setAttachmentImage] = useState(null);
  const [fileUrls, setFileUrls] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sessionData = useSelector(state => state.auth.sessionData);
  const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
  const imageMap = {
    news1,
    news2,
    news3,
    news4,
    news5,
    news6,
    news7,
    newView1,
    newView2,
    newView3,
    newView4,
    newView5,
    newView6,
    slide1,
    slide2,
    Avatar
  };
useEffect(() => {
  setMostViewedData(dbData.mostViewed);
}, []);
  useEffect(() => {
    if (id && headers) {
      getNewsDetails(id, headers, dispatch, (response) => {
        if (response.result === 'SUCCESS') {
          const responseData = response.data;
          const newsData = responseData?.map(news => ({
            ...news,
            fileAttachments: news?.fileAttachments ? JSON.parse(news?.fileAttachments) : []
          }));
          setNewsDetail(newsData[0]);
        } else {
          const error = response?.error;
          showToast(error.severity, error.errorMsg || error.summary);
        }
        setLoading(false);
      });
    }
  }, [id, headers, dispatch, showToast]);

  useEffect(() => {
    if (newsDetail?.fileAttachments?.length > 0) {
      const attachments = newsDetail?.fileAttachments;

      attachments.forEach(attachment => {
        getFileDetails(attachment.key, headers, dispatch, response => {
          if (response.result === 'SUCCESS') {
            const url = URL.createObjectURL(response.data);
            if (attachment?.attachmentType === "coverImage") {
              setAttachmentImage(url);
            } else {
              setFileUrls(prev => [...prev, { url, name: attachment.name, key: attachment.key }]);
            }
          } else {
            const error = response?.error;
            showToast(error.severity, error.errorMsg || error.summary);
          }
        });
      });
    }
  }, [newsDetail?.fileAttachments, headers, dispatch, showToast]);

  const handleDownload = (url, name) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(err => {
        showToast("error", "Failed to download file");
      });
  };

  const handleCategoryClick = (categoryId, categoryName) => {
    const formattedCategory = categoryName?.replace(/ /g, '-')?.replace(/-+$/, '');
    navigate(`/news-category?categoryID=${categoryId}&categoryName=${formattedCategory}`);
  };


  if (loading) {
    return (
      <div className="loading">
        <i className="pi pi-spin pi-spinner"></i>
      </div>
    );
  }

  if (!newsDetail) {
    return (
      <div className="no-data">
        <p>No news details available.</p>
      </div>
    );
  }

  const { title, description,keywords, createdAt } = newsDetail;
  return (
    <Layout>
      <div className="back-button">
        <Link to="/sporting-sanction">
          <i className="pi pi-arrow-left"></i>
          Back to Sanctions
        </Link>
      </div>
      <section className="news-tab news-details-section general-news-section">
        <div className="grid align-items-center justify-content-start">
          <div className="col-9">
            <h1>{title}</h1>
          </div>
        </div>
        <div className="grid">
          <div className="col-12">
            <div className="new-list-section">
              <div className="list-wrapper">
                <div className="news-list">
                  <div className="card">
                    <div className="col-12 p-0">
                      <div className="flex align-items-center justify-content-between">
                        <ul className="chips-ul gap-3 mb-4">
                          {keywords &&
                            keywords
                              ?.split(",")
                              ?.map((keyword, index) => <li key={index}>{keyword}</li>)}
                        </ul>
                        <ul className="flex list-none align-items-center gap-4">
                          <li>
                            <i className="pi pi-calendar"></i>{" "}
                            <span>{DateFormat(createdAt)}</span>
                          </li>
                        </ul>
                      </div>
                      {attachmentImage && (
                        <PR.Image
                          src={attachmentImage}
                          alt={title}
                          className="news-img"
                        />
                      )}
                    </div>
                    <div className="col-12 p-0">
                      <div className="content">
                      <div dangerouslySetInnerHTML={{ __html: description }} />
                      </div>
                      {fileUrls && fileUrls?.length > 0 && (
                        <div className='attachments'>
                          <span className='heading-span'>Attached Files:</span>
                          {fileUrls?.map((file, index) => (
                            <PR.Button
                              key={file.key}
                              icon="pi pi-book"
                              label={file.name}
                              className="download-button hide-on-download"
                              onClick={() => handleDownload(file.url, file.name)}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3" style={{display:'none'}}>
            <MostViewed mostViewedData={mostViewedData} imageMap={imageMap}/>
            <TopCategories
              heading={"Top news categories"}
              handleCategoryClick={handleCategoryClick}
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SportingSanctionDetail;
