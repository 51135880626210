import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Reports.scss";
import Layout from "../../layouts/Layout";
import MenuBar from "../../layouts/MenuBar";
import * as PR from "../../prime-react/index";
import { news1, news2, news3, filterIcon } from "../../assets/images/index";
import AppliedFilter from "../../shared/Alerts/AppliedFilter";
import SearchFilter from "../../shared/Alerts/SearchFilter";
import { cleanData, DateFormat, pagecount, sortByCreatedAt } from "../../utils/reuse";
import { RAW_FILTER_DATA } from "../../shared/common";
import { useDispatch, useSelector } from "react-redux";
import { deleteReport, getReports, getSports } from "../../services/api";
import moment from "moment";
import { ErrorMessage, useToast } from "../../context/ToastContext";
import { isEmpty, some } from 'lodash';
import { setReportFilter } from '../../store/filter.slice';

const Reports = () => {
  const { showToast, clearError } = useToast();
  const deleteNewsError = 'general_delete_report_error'

  const {pathname} = useLocation();
  const isRegulator = useSelector((state) => state.auth.role) === "regulator";
  const isAnalyst = useSelector((state) => state.auth.role) === "analyst";
  const menuRight = useRef({});
  const [filterDialogVisible, setFilterDialogVisible] = useState(false);
  const [filterVisible, setFilterVisible] = useState(true);
  const [reportsData, setReportsData] = useState([]);
  const [reportId, setReportId] = useState(null);
  const reportFilterState = useSelector(state => state.reportFilter);
  const [selectedFilters, setSelectedFilters] = useState(pathname==='/reports'? reportFilterState : {
      status: [],
      alertType: [],
      reportType: [],
      receiver: [],
      sports: [],
      date: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.auth.sessionData);
  const headers = useMemo(
    () => ({ sessionid: sessionData.sessionId }),
    [sessionData.sessionId]
  );
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const pageCount = pagecount;
  const navigate = useNavigate();
  const imageMap = {
    "suspicious betting": news1,
    summary: news2,
    others: news3,
  };

  const getReportsData = useCallback(
    (filters, page = 1) => {
      setLoading(true);
      const params = {
        offset: (page - 1) * pageCount,
        limit: pageCount,
      };
      if (filters.reportType && filters.reportType?.length > 0) {
        params.type = filters.reportType?.map((rt) => rt?.id)?.join(",");
      }
      if (filters.sports && filters.sports?.length > 0) {
        params.sport = filters.sports?.map((s) => s?.id)?.join(",");
      }
      if (filters.receiver && filters.receiver?.length > 0) {
        params.receivers = filters.receiver?.map((r) => r?.id)?.join(",");
      }
      if (filters?.date?.date && filters.date?.date?.length > 0) {
        if (isRegulator || (!isRegulator && filters.date?.dateRange !== "")) {
          const startDate = moment(filters?.date?.date[0]).format("YYYY-MM-DD");
          const endDate = filters?.date?.date[1]
            ? moment(filters?.date?.date[1]).format("YYYY-MM-DD")
            : startDate;
          params.startDate = startDate;
          params.endDate = endDate;
        }
      }
      getReports(params, headers, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          const responseData = response.data?.length ? sortByCreatedAt(response.data) : [];
          const totalPages = Math.ceil(responseData?.length);
          setReportsData(responseData);
          setTotalPages(responseData?.length < pageCount ? page : totalPages);
        } else {
          const error = response.error;
          showToast(error.severity, error?.errorMsg || error?.summary);
          setReportsData([]);
        }
        setLoading(false);
      });
     
    },
     // eslint-disable-next-line
    [dispatch, pageCount, headers]
  );

  useEffect(() => {
    if (isAnalyst) {
      getReportsData(selectedFilters, currentPage);
    }
  }, [getReportsData, selectedFilters, currentPage, isAnalyst, refresh]);

  const gotoCreateReport = () => {
    navigate("/create-report");
  };

  const gotoDownloadReport = (id) => {
    navigate(`/report-detail?id=${id}`);
  };

  const gotoEditReport = (id) => {
    navigate(`/edit-report?id=${id}`);
  };

  const filterHideShow = () => {
    setFilterVisible(!filterVisible);
  };

  const setFilters = (data) => {
    setSelectedFilters(data);
    dispatch(setReportFilter(data));
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // for regulator section
  const [sports, setSports] = useState([]);
  const [selectedSports, setSelectedSports] = useState();
  const [selectedReportType, setSelectedReportType] = useState();
  const [dates, setDates] = useState(null);
  const reportTypes = RAW_FILTER_DATA.reportTypeFilter;

  // Get list of all the sports
  const getSportsHandler = useCallback(() => {
    getSports(null, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const sports = responseData;
        const result = sports?.length > 0 ? sports?.sort((a, b) => a.name.localeCompare(b.name)) : [];
        setSports(result);
        if (result?.length === 0) {
          showToast("warn", "No records found");
        }
      } else {
        setSports([]);
        const error = response.error;
        showToast(error.severity, error?.errorMsg || error?.summary);
      }
    });
  }, [dispatch, headers, showToast]);

  useEffect(() => {
    if (isRegulator) {
      getSportsHandler();
    }
     // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const regulatorFilters = {};
    const selectedDate = {};
    selectedDate.date = dates;

    if (isRegulator) {
      if (selectedSports) {
        regulatorFilters.sports = [selectedSports];
      }
      if (selectedReportType) {
        regulatorFilters.reportType = [selectedReportType];
      }
      if (dates) {
        regulatorFilters.date = selectedDate;
      }
      getReportsData(regulatorFilters, currentPage);
    }
    // eslint-disable-next-line
  }, [selectedSports, selectedReportType, dates]);
  const deleteReportHandler = (id) => {
    clearError(deleteNewsError)
    setLoading(true);
    deleteReport(id, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        setReportId(null);
        setRefresh(!refresh);
        showToast("success", "Report deleted successfully");
        navigate("/reports");
      } else {
        setLoading(false);
        const error = response.error;
        showToast(error.severity, error?.errorMsg || error?.summary, deleteNewsError);
      }
    });
  };

  return (
    <Layout backBtn={"home"} backBtnLabel="Home">
      {isRegulator && <MenuBar />}
      <section className="news-tab reports-section">
        <div className="grid">
          <div className="col-12">
            <div className="new-list-section">
              <div className="flex align-items-center justify-content-between mb-5">
                <div className="left">
                  <h1>{isRegulator ? "All reports" : "Reports"}</h1>
                </div>
                {/* filters for reports  */}
                <div className="right flex gap-3">
                  {isRegulator ? (
                    // filter for regulator
                    <div className="status-filter-section flex gap-2">
                      <div className="sports">
                        <PR.Dropdown
                          value={selectedSports}
                          onChange={(e) => setSelectedSports(e.value)}
                          options={sports}
                          optionLabel="name"
                          placeholder="Select sports"
                          className="reports-drop-down"
                          showClear
                          filter
                        />
                      </div>
                      <div className="report-types">
                        <PR.Dropdown
                          value={selectedReportType}
                          onChange={(e) => setSelectedReportType(e.value)}
                          options={reportTypes}
                          optionLabel="name"
                          placeholder="Select Report type"
                          className="reports-drop-down"
                          showClear
                        />
                      </div>
                      <div className="card">
                        <div className="relative custom-clear-calendar">
                          <PR.Calendar
                            value={dates}
                            onChange={(e) => setDates(e.value)}
                            dateFormat="dd/mm/yy"
                            selectionMode="range"
                            iconPos="left"
                            readOnlyInput
                            showIcon
                            className="date-calendar"
                            placeholder="Date"
                            maxDate={new Date()}
                          />
                          {dates && (
                            <div className="custom-clear">
                              <i
                                className="pi pi-times"
                                onClick={() => setDates(null)}
                              ></i>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="status-filter-section flex gap-2">
                      <PR.Button
                        label="Create Report"
                        type="submit"
                        icon="pi pi-plus"
                        severity="secondary"
                        className="download-button mr-2"
                        onClick={gotoCreateReport}
                      />
                      <PR.Button
                        className={cleanData(selectedFilters) ? 'search-filters-button search-filter-applied' : 'search-filters-button'}
                        iconPos="left"
                        outlined
                        onClick={() => setFilterDialogVisible(true)}
                      >
                        <PR.Image
                          src={filterIcon}
                          alt="Filter Icon"
                          className="icon"
                        />
                        <span className="label">Search Filters</span>
                      </PR.Button>
                    </div>
                  )}
                </div>
              </div>

              {(some(selectedFilters, v => !isEmpty(v))) &&
                <AppliedFilter
                  selectedFilters={selectedFilters}
                  setFilters={setFilters} 
                  filterVisible={filterVisible} 
                  filterHideShow={filterHideShow}
                />
              }

              <div className="list-wrapper">
                {loading ? (
                  <div className="loading">
                    <i className="pi pi-spin pi-spinner"></i>
                  </div>
                ) : reportsData && reportsData.length > 0 ? (
                  reportsData?.map((report, index) => (
                    <React.Fragment key={index}>
                      <div className="flex gap-4 news-list">
                        <div className="report-cover p-0">
                          <div className="relative">
                            <PR.Image
                              src={imageMap[report?.reportType] || news3}
                              alt={report?.title}
                              className="news-img"
                            />
                            <div className="overlay">
                              <h2>{report?.reportType}</h2>
                            </div>
                          </div>
                        </div>
                        <div className="w-full py-2 px-0">
                          <div className="flex flex-column justify-content-between h-full">
                            <div className="flex justify-content-between">
                              <Link
                                to={`/report-detail?id=${report?.reportID}`}
                                className="flex flex-column"
                              >
                                <h3 className="title">{report?.title}</h3>
                                <div className="left description flex align-items-end">
                                  <div
                                    className="desc-content"
                                    dangerouslySetInnerHTML={{
                                      __html: report?.description,
                                    }}
                                  />
                                </div>
                              </Link>
                              <div className="right flex flex-row gap-2">
                                <PR.Button
                                  label="View"
                                  type="submit"
                                  icon="pi pi-eye"
                                  severity="secondary"
                                  className={`${
                                    isRegulator && "regulator"
                                  } download-button`}
                                  onClick={() =>
                                    gotoDownloadReport(report?.reportID)
                                  }
                                />
                                {isAnalyst && (
                                  <div className="dropdown-popup">
                                    <PR.Menu
                                      model={[
                                        {
                                          label: "Edit Details",
                                          icon: "pi pi-pencil",
                                          command: () =>
                                            gotoEditReport(report?.reportID),
                                        },
                                        {
                                          label: "Delete Report",
                                          icon: 'pi pi-trash',
                                          command: () => setReportId(report?.reportID),
                                      }
                                      ]}
                                      popup
                                      ref={(el) =>
                                        (menuRight.current[
                                          report?.reportID
                                        ] = el || {})
                                      }
                                      id={`popup_menu_right_${report?.reportID}`}
                                      className="export-popup"
                                      popupAlignment="right"
                                    />
                                    <PR.Button
                                      icon="pi pi-ellipsis-v"
                                      className="info-button"
                                      onClick={(event) =>
                                        menuRight.current[
                                          report?.reportID
                                        ]?.toggle(event)
                                      }
                                      aria-controls={`popup_menu_right_${report?.reportID}`}
                                      aria-haspopup
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="flex align-items-center justify-content-between">
                              <ul className="flex list-none align-items-center gap-2">
                                <li>
                                  <span className="author capitalize">
                                    {report?.reportType}
                                  </span>
                                </li>
                                {(report?.sportName && report?.reportType !== 'summary') &&
                                  <>
                                    <li className="vertical-gray" />
                                    <li>
                                      <span className="author capitalize">
                                        {report?.sportName}
                                      </span>
                                    </li>
                                  </>
                                }
                              </ul>
                              <ul className="flex list-none align-items-center">
                                <li>
                                  <i className="pi pi-calendar"></i>{" "}
                                  <span>{DateFormat(report?.createdAt)}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="hr-line mt-4 mb-4"></div>
                    </React.Fragment>
                    
                  ))
                ) : (
                  <div className="flex justify-content-center">
                    <p className="no-data m-0 text-bluegray-500">
                      No reports found
                    </p>
                  </div>
                )}
              </div>

              {/* Pagination Section */}
              <div className="pagination">
                <div className="grid grid-nogutter align-items-center justify-content-between">
                  <span>Page {currentPage}</span>
                  <ul className="flex list-none align-items-center gap-3">
                    <li
                      className={currentPage === 1 ? "disabled" : ""}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Previous
                    </li>
                    <li
                      className={
                        currentPage === totalPages ||
                        reportsData?.length < pageCount ||
                        !reportsData
                          ? "disabled"
                          : ""
                      }
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Filter Modal Dialog */}
      <PR.Dialog
        visible={filterDialogVisible}
        onHide={() => setFilterDialogVisible(false)}
        className="response-dialog filter-dialog"
      >
        <SearchFilter
          setFilterDialogVisible={setFilterDialogVisible}
          setFilters={setFilters}
          staticOptionData={RAW_FILTER_DATA}
          selectedFilters={selectedFilters}
        />
      </PR.Dialog>
      <PR.Dialog
        visible={reportId ? true : false} className="news-dialog" header="Delete report" onHide={() =>{ setReportId(null); clearError(deleteNewsError)}}
        footer={
          <>
            <ErrorMessage id={deleteNewsError} />
            <PR.Button
              label="Cancel"
              className="p-button-text custom-cancel-button"
              onClick={() => { clearError(deleteNewsError); setReportId(null) }}
            />
            <PR.Button
              label="Delete"
              className="p-button-danger custom-delete-button"
              onClick={() => deleteReportHandler(reportId)}
            />
          </>
        }
      >
        <div className="confirmation-content">
          <span>Are you sure you want to delete this report?</span>
        </div>
      </PR.Dialog>
    </Layout>
  );
};

export default Reports;
