import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../../layouts/Layout";
import * as PR from "../../../../prime-react/index";
import "./CreateReport.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getGroupsWithPermission, getSports } from "../../../../services/api";
import { fileUploadLimit, maxNumberOfFiles, permission,roleIds } from "../../../../utils/reuse";
import { useToast } from "../../../../context/ToastContext";

const CreateReport = () => {
  const { showToast } = useToast();
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let fileUploadRef = useRef(null);
  const sessionData = useSelector((state) => state.auth.sessionData);
  const headers = useMemo(() => {
    return {
      sessionid: sessionData.sessionId,
      "Content-Type": "multipart/form-data",
    };
  }, [sessionData.sessionId]);

  const [regulators, setRegulators] = useState([]);
  const [governingBodies, setGoverningBodies] = useState([]);
  const [sports, setSports] = useState([]);

  const validationSchema = Yup.object()
    .shape({
      reportTitle: Yup.string().required("Report title is required"),
      attachments: Yup.array()
        .max(maxNumberOfFiles, `You can upload up to ${maxNumberOfFiles} files`)
        .test("fileSize", `Each file must be less than ${fileUploadLimit.label}`, (files) =>
          files.every((file) => file.size <= fileUploadLimit.value)
        ),
      reportDescription: Yup.string().required("Report description is required"),
      reportType: Yup.object().nullable().required("Report type is required"),
      sport: Yup.object().when('reportType', {
        is: (reportType) => {
          return reportType?.name?.toLowerCase() !== "summary";
        },
        then: () => Yup.object().nullable().required("Sport is required"),
        otherwise: () => Yup.object().nullable(),
      }),
      regulators: Yup.array().of(Yup.object().nullable()),
      governingBodies: Yup.array().of(Yup.object().nullable()),
    }).test("oneOfRequired", "At least one regulator or governing body is required",
      function ({ regulators = [], governingBodies = [] }) {
        return regulators?.length > 0 || governingBodies?.length > 0;
      }
    );

  const initialValues = {
    reportTitle: "",
    attachments: [],
    reportDescription: "",
    reportType: null,
    sport: null,
    regulators: [],
    governingBodies: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: (values) => {
      if (values.regulators?.length === 0 && values.governingBodies?.length === 0) {
        formik.setErrors({...formik.errors, oneOfRequired: "At least one regulator or governing body is required",});
        return;
      }
      let data = {
        title: values.reportTitle,
        description: text,
        reportType: values.reportType.name.toLowerCase(),
        receivers: [
          ...values.regulators?.map((reg) => reg.id),
          ...values.governingBodies?.map((gb) => gb.id),
        ],
      };
      let sportName = ''
      if (data?.reportType !== 'summary') {
        data.sportID = values.sport.id;
        sportName = values.sport.name;
      }
      let attachments = [];
      if (values.attachments?.length > 0) {
        attachments.push(...values.attachments);
      }
      navigate("/preview-report", {state: {reportData: data, attachments , sportName}});
    },
  });

  useEffect(() => {
    getGroupsWithPermission(roleIds.regulator, permission?.reports,headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const result = responseData?.length > 0 ? responseData?.sort((a, b) => a.name.localeCompare(b.name)) : [];
        setRegulators(result);
      } else {
        const error = response.error;
        showToast(error.severity, error?.errorMsg || error?.summary);
      }
    });

    getGroupsWithPermission(roleIds.governingbody,permission?.reports, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response?.data;
        const result = responseData?.length > 0 ? responseData?.sort((a, b) => a.name.localeCompare(b.name)) : [];
        setGoverningBodies(result);
      } else {
        const error = response.error;
        showToast(error.severity, error?.errorMsg || error?.summary);
      }
    });

    getSports(null, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const sports = response?.data;
        setSports(sports?.length > 0 ? sports?.sort((a, b) => a.name.localeCompare(b.name)) : []);
        if (sports?.length === 0) {
          showToast("warn", "No records found");
        }
      } else {
        setSports([]);
        const error = response.error;
        showToast(error.severity, error?.errorMsg || error?.summary);
      }
    });
  }, [dispatch, headers, showToast]);

  const handleFileSelect = (event) => {

    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
    const currentAttachments = formik.values.attachments || [];
    const newAttachment = event.files[0];
    const duplicateFiles = currentAttachments.some(existingFile => existingFile.name === newAttachment.name)

    if (duplicateFiles) {
      showToast("warn", "File names must be unique. Please rename your file(s) before uploading.");
      fileUploadRef.current.clear();
    } else if (currentAttachments?.length + 1 > maxNumberOfFiles) {
      showToast("warn", `You can upload a maximum of ${maxNumberOfFiles} files.`);
      fileUploadRef.current.clear();
    } else {
      const updatedAttachments = [...currentAttachments, newAttachment];
      formik.setFieldValue('attachments', updatedAttachments);
    }
  }

  const handleDeleteFile = (index) => {
    const updatedAttachments = [...formik.values.attachments];
    updatedAttachments?.splice(index, 1);
    formik.setFieldValue("attachments", updatedAttachments);
  };
  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
      </span>
    );
  };

  const header = renderHeader();
  const type = [
    { name: "Suspicious betting" },
    { name: "Summary" },
    { name: "Others" },
  ];

  return (
    <Layout backBtn={'reports'} backBtnLabel="Reports">
      <section className="news-tab news-details-section general-news-section">
        <div className="flex align-items-center justify-content-between mb-4">
          <div className="left">
            <h1>Create Report</h1>
          </div>
          <div className="right">
            <PR.Button
              label="Save & Preview"
              className="action-buttons save-button"
              onClick={formik.handleSubmit}
            />
          </div>
        </div>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <div className="grid">
            <div className="col-12">
              <div className="new-list-section">
                <div className="list-wrapper">
                  <div className="news-list edit-details">
                    <div className="card">
                      <div className="col-12 p-0 mb-4">
                        <div className="flex flex-column form-input gap-2">
                          <label htmlFor="reportTitle">
                            Report title <span className="text-red">*</span>
                          </label>
                          <PR.InputText
                            id="reportTitle"
                            name="reportTitle"
                            type="text"
                            className={`input-field ${
                              formik.errors.reportTitle &&
                              formik.touched.reportTitle
                                ? "is-invalid"
                                : ""
                            }`}
                            value={formik.values.reportTitle}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.reportTitle &&
                            formik.touched.reportTitle && (
                              <div className="error-message">
                                {formik.errors.reportTitle}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-12 p-0">
                        <div className="flex flex-column gap-2">
                          <span htmlFor="label">Attach Files</span>
                          <div className="card create-file-upload flex align-items-center">
                          <PR.FileUpload
                            auto
                            mode="advanced"
                            name="attachments"
                            accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            chooseLabel="Choose Files"
                            multiple
                            ref={fileUploadRef}
                            onSelect={handleFileSelect}
                            onFocus={() => formik.setFieldTouched(`attachments`)}
                            customUpload
                          />

                            <span className="ml-2">
                              (Add up to {maxNumberOfFiles} files less than {fileUploadLimit.label} each)
                            </span>
                          </div>
                          {formik.values.attachments && (
                            <div className="uploaded-file-container flex flex-column gap-2">
                              {formik.values.attachments?.map((attachment, index) => (
                                <div className="uploaded-files" key={index}>
                                  {attachment.name}
                                  <i className="pi pi-trash" onClick={() => handleDeleteFile(index)} />
                                </div>
                              ))}
                            </div>
                          )}
                          {formik.errors.attachments && (formik.touched.attachments || formik.submitCount > 0) && (
                            <div className="error-message">
                              {Array.isArray(formik.errors.attachments) ? (
                                Array.from(new Set(formik.errors?.attachments))?.map((error, index) => (
                                  <div key={index}>{error}</div>
                                ))
                              ) : (
                                <div>{formik.errors.attachments}</div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 p-0 mt-4">
                        <div className="flex flex-column gap-2">
                          <span className="label">
                            Report description{" "}
                            <span className="text-red">*</span>
                          </span>
                          <div className="card content-editor">
                            <PR.Editor
                              id="reportDescription"
                              value={text}
                              name="reportDescription"
                              onTextChange={(e) => {
                                setText(e.htmlValue || "");
                                formik.setFieldValue(
                                  "reportDescription",
                                  e.htmlValue || ""
                                );
                              }}
                              headerTemplate={header}
                              style={{ height: "450px" }}
                            />
                          </div>
                          {formik.errors.reportDescription &&
                            formik.touched.reportDescription && (
                              <div className="error-message">
                                {formik.errors.reportDescription}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="grid mt-4">
                        <div className="col-6">
                          <div className="flex flex-column form-input gap-2">
                            <label htmlFor="reportType">
                              Report type <span className="text-red">*</span>
                            </label>
                            <PR.Dropdown
                              inputId="reportType"
                              value={formik.values.reportType}
                              name="reportType"
                              onChange={(e) =>
                                formik.setFieldValue("reportType", e.value)
                              }
                              options={type}
                              optionLabel="name"
                              placeholder="Select Report type"
                              className={`w-full ${
                                formik.errors.reportType &&
                                formik.touched.reportType
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {formik.errors.reportType &&
                              formik.touched.reportType && (
                                <div className="error-message">
                                  {formik.errors.reportType}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-6">
                          {formik.values?.reportType?.name?.toLowerCase() !== 'summary' &&
                            <div className="flex flex-column form-input gap-2">
                              <label htmlFor="sport">
                                Sport <span className="text-red">*</span>
                              </label>
                              <PR.Dropdown
                                inputId="sport"
                                value={formik.values.sport}
                                name="sport"
                                onChange={(e) =>
                                  formik.setFieldValue("sport", e.value)
                                }
                                filter
                                resetFilterOnHide
                                options={sports}
                                optionLabel="name"
                                placeholder="Select sport"
                                className={`w-full ${formik.errors.sport && formik.touched.sport
                                    ? "is-invalid"
                                    : ""
                                  }`}
                              />
                              {formik.errors.sport && formik.touched.sport && (
                                <div className="error-message">
                                  {formik.errors.sport}
                                </div>
                              )}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="grid mt-4">
                        <div className="col-12 pt-0 pb-0">
                          <label htmlFor="regulators">
                            Publish and Send by email to{" "}
                            <span className="text-red">*</span>
                          </label>
                        </div>
                        <div className="col-6">
                          <div className="flex flex-column form-input gap-2">
                            <PR.MultiSelect
                              inputId="regulators"
                              value={formik.values.regulators}
                              name="regulators"
                              onChange={(e) => {
                                formik.setFieldValue("regulators", e.value);
                                formik.setFieldTouched(
                                  "regulators",
                                  true,
                                  false
                                );
                              }}
                              options={regulators}
                              optionLabel="name"
                              filter
                              resetFilterOnHide
                              placeholder="Regulators"
                              maxSelectedLabels={3}
                              className={`w-full ${
                                formik.errors.regulators &&
                                formik.touched.regulators
                                  ? "is-invalid"
                                  : ""
                              }`}
                              showSelectAll
                              pt={{
                                  checkboxContainer: {
                                      onClick: (e) => {
                                          e.stopPropagation();
                                          const parent = e.target?.parentNode;
                                          if (parent && typeof parent.click === 'function') {
                                              parent.click();
                                          }
                                      },
                                  },
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="flex flex-column form-input gap-2">
                            <PR.MultiSelect
                              inputId="governingBodies"
                              value={formik.values.governingBodies}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "governingBodies",
                                  e.value
                                );
                                formik.setFieldTouched("governingBodies", true);
                              }}
                              options={governingBodies}
                              optionLabel="name"
                              filter
                              resetFilterOnHide
                              placeholder="Sport Governing Bodies"
                              maxSelectedLabels={3}
                              className={`w-full ${
                                formik.errors.governingBodies &&
                                formik.touched.governingBodies
                                  ? "is-invalid"
                                  : ""
                              }`}
                              showSelectAll
                              pt={{
                                  checkboxContainer: {
                                      onClick: (e) => {
                                          e.stopPropagation();
                                          const parent = e.target?.parentNode;
                                          if (parent && typeof parent.click === 'function') {
                                              parent.click();
                                          }
                                      },
                                  },
                              }}
                            />
                          </div>
                        </div>
                        {formik.errors.oneOfRequired &&
                          (formik.touched.regulators ||
                            formik.touched.governingBodies) && (
                            <div className="col-12 error-message mt-2">
                              {formik.errors.oneOfRequired}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-right mt-4">
            <PR.Button
              label="Save & Preview"
              className="action-buttons save-button"
              type="submit"
            />
          </div>
        </form>
      </section>
    </Layout>
  );
};

export default CreateReport;
