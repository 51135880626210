import React from "react";
import * as PR from "../../prime-react/index";
import "./Help.scss";
import Layout from "../../layouts/Layout";
import { memberProfilesUserManagement, raisingAlert, respondingAlert, chatFunction, caseUpdates, memberAnalytics, videoPoster1, videoPoster2, Search } from "../../assets/videos";
import {forgotPasswordScreen, otpScreen, loginScreen} from '../../assets/images'
import { Link } from "react-router-dom";
import { supportEmail } from "../../utils/reuse";
import { useSelector } from "react-redux";


const Help = () => {
    const showTab = useSelector((state) => state.auth.role) !== "regulator";
    return (
        <>
            <Layout>
                <section className="help-page-section">
                    <div className="help-wrapper">
                        <div className="m-0">
                            <h1>Help</h1>
                        </div>
                        <div className="help-tabs-section">
                            <PR.TabView className="vertical-tabs">

                                <PR.TabPanel header="New platform - First time login">
                                    <div className="help-content">
                                        <h2>New platform - First time login</h2>
                                        <ul className="mt-2">
                                            <li>Your previous JIRA-based account has been migrated over to the new platform, however a password reset is required for the account to become active.</li>
                                            <li>To login to your account for the first time you will need to navigate to the domain <Link to={`https://alert.ibia.bet/`} target="_blank"> https://alert.ibia.bet/</Link>. </li>
                                            <li>On the sign in page, select 'Can't access your account'. </li>
                                        </ul>
                                        <div className="text-center login-images">
                                            <PR.Image src={loginScreen} alt="Login Screen"/>
                                            <PR.Image src={otpScreen} alt="OTP Screen"/>
                                            <PR.Image src={forgotPasswordScreen} alt="forgot Password Screen"/>
                                        </div>
                                        <p>This will prompt a 'Forgot Password' screen. Enter your email address for the email that you currently use in JIRA, and an email will be sent to that address which includes a link for you to set your password and login. Once these credentials have been entered a One-Time Password (OTP) will also be required for login, which will also be sent to your email address. </p>
                                        <p>Should you have any issues with logging in for the first time, please don't hesitate to contact <Link to={`mailto:${supportEmail}`}>{supportEmail}</Link>. </p>
                                    </div>
                                </PR.TabPanel>

                                <PR.TabPanel header="1. Logging in and user management">
                                    <div className="help-content">
                                        <p>Please find below a series of step-by-step video tutorials to assist with navigating and operating within the IBIA platform.</p>
                                        <p>Should you require further assistance please don't hesitate to get in contact with us at <Link to={`mailto:${supportEmail}`}>{supportEmail}</Link>.</p>
                                        <h2 className="mt-3">1. Logging in and user management</h2>
                                        <ul className="mt-2">
                                            <li>One-time password login</li>
                                            <li>Changing your password</li>
                                            <li>Sports permissioning</li>
                                        </ul>
                                        <div className="video-wrapper">
                                            <video className='video' controls poster={videoPoster1}>
                                                <source src={memberProfilesUserManagement} type="video/mp4"/>
                                            </video>
                                        </div>
                                    </div>
                                </PR.TabPanel>
                                { showTab && 
                                <PR.TabPanel header="2. Raising an alert">
                                    <div className="help-content">
                                        <h2>2. Raising an alert</h2>
                                        <ul className="mt-2">
                                            <li>Step-by-step of how to raise an alert in the platform</li>
                                        </ul>
                                        <div className="video-wrapper">
                                            <video className='video' controls poster={videoPoster2}>
                                                <source src={raisingAlert} type="video/mp4"/>
                                            </video>
                                        </div>
                                    </div>
                                </PR.TabPanel>
                                }
                               { showTab && 
                                <PR.TabPanel header="3. Responding to an alert ">
                                    <div className="help-content">
                                        <h2>3. Responding to an alert</h2>
                                        <ul className="mt-2">
                                            <li>Walkthrough of responding to an alert and the information required</li>
                                        </ul>
                                        <div className="video-wrapper">
                                            <video className='video' controls poster={videoPoster2}>
                                                <source src={respondingAlert} type="video/mp4"/>
                                            </video>
                                        </div>
                                    </div>
                                </PR.TabPanel> 
                                }
                                 { showTab && 
                                <PR.TabPanel header="4. Chat function">
                                    <div className="help-content">
                                        <h2>4. Chat function</h2>
                                        <ul className="mt-2">
                                            <li>A demonstration to the new alert follow-up workflow via the in-platform chat function</li>
                                        </ul>
                                        <div className="video-wrapper">
                                            <video className='video' controls poster={videoPoster2}>
                                                <source src={chatFunction} type="video/mp4"/>
                                            </video>
                                        </div>
                                    </div>
                                </PR.TabPanel>
                                }
                                { showTab && 
                                <PR.TabPanel header="5. Case Updates">
                                    <div className="help-content">
                                        <h2>5. Case Updates</h2>
                                        <ul className="mt-2">
                                            <li>An introduction to the Case Update page designed to streamline the ability for operators to monitor case updates and sanctions relevant to them.</li>
                                        </ul>
                                        <div className="video-wrapper">
                                            <video className='video' controls poster={videoPoster2}>
                                                <source src={caseUpdates} type="video/mp4"/>
                                            </video>
                                        </div>
                                    </div>
                                </PR.TabPanel>
                                }
                                { showTab && 
                                <PR.TabPanel header="6. Member analytics">
                                    <div className="help-content">
                                        <h2>6. Member analytics </h2>
                                        <ul className="mt-2">
                                            <li>Displaying and how to navigate the real-time member analytics page.</li>
                                        </ul>
                                        <div className="video-wrapper">
                                            <video className='video' controls poster={videoPoster2}>
                                                <source src={memberAnalytics} type="video/mp4"/>
                                            </video>
                                        </div>
                                    </div>
                                </PR.TabPanel>
                                }
                                { showTab && 
                                <PR.TabPanel header="7. Search function">
                                    <div className="help-content">
                                        <h2>7. Search function </h2>
                                        <div className="video-wrapper">
                                            <video className='video' controls poster={videoPoster2}>
                                                <source src={Search} type="video/mp4"/>
                                            </video>
                                        </div>
                                    </div>
                                </PR.TabPanel>
                                }

                            </PR.TabView>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default Help;