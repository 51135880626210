import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../../layouts/Layout";
import * as PR from "../../../../prime-react/index";
import "./EditReport.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getSports,
  getReportById,
  getFileDetails,
  getGroupsWithPermission,
} from "../../../../services/api";
import { fileUploadLimit, maxNumberOfFiles, permission,roleIds, typeFilter } from "../../../../utils/reuse";
import { useToast, ErrorMessage } from "../../../../context/ToastContext";

const EditReport = () => {
  const { showToast, clearError } = useToast();
  const [reportDetail, setReportDetail] = useState({});
  const [initialRegulators, setInitialRegulators] = useState([]);
  const [initialGoverningBodies, setInitialGoverningBodies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const fileUploadRef = useRef(null);
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get("id");
  const sessionData = useSelector((state) => state.auth.sessionData);
  const headers = useMemo(() => {
    return {
      sessionid: sessionData.sessionId,
      "Content-Type": "multipart/form-data",
    };
  }, [sessionData.sessionId]);

  const [regulators, setRegulators] = useState([]);
  const [governingBodies, setGoverningBodies] = useState([]);
  const [sports, setSports] = useState([]);
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  const validationSchema = Yup.object()
  .shape({
    title: Yup.string().required("Report title is required"),
    attachments: Yup.array()
      .max(maxNumberOfFiles, `You can upload up to ${maxNumberOfFiles} files`)
      .test("fileSize", `Each file must be less than ${fileUploadLimit.label}`, (files) =>
        files.every((file) => file.size <= fileUploadLimit.value)
      ),
    reportDescription: Yup.string().required("Report description is required"),
    reportType: Yup.object().nullable().required("Report type is required"),
    sport: Yup.string().when('reportType', {
      is: (reportType) => {
        return reportType?.name?.toLowerCase() !== "summary";
      },
      then: () => Yup.string().nullable().required("Sport is required"),
      otherwise: () => Yup.string().nullable(),
    }),
    regulators: Yup.array().of(Yup.object().nullable()),
    governingBodies: Yup.array().of(Yup.object().nullable()),
  })
  .test(
    "totalFileLimit",
    `You can upload a maximum of ${maxNumberOfFiles} files (including existing and new files)`,
    function (values) {
      const totalFiles = values.attachments.length + attachments.length;
      return totalFiles <= maxNumberOfFiles;
    }
  )
  .test(
    "oneOfRequired",
    "At least one regulator or governing body is required",
    function (values) {
      const { regulators, governingBodies } = values;
      return (
        (regulators && regulators?.length > 0) ||
        (governingBodies && governingBodies?.length > 0)
      );
    }
  );


  const [initialValues, setInitialValues] = useState({
    title: "",
    attachments: [],
    reportDescription: "",
    reportType: null,
    sport: null,
    regulators: [],
    governingBodies: [],
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize:true,
    onSubmit: (values) => {
      const totalFiles = values.attachments.length + attachments.length;
      if (totalFiles > maxNumberOfFiles) {
        formik.setErrors({
          ...formik.errors,
          attachments: `You can upload a maximum of ${maxNumberOfFiles} files (including existing and new files)`,
        });
        return;
      }
      if (values.regulators.length === 0 && values.governingBodies.length === 0) {
        formik.setErrors({
          ...formik.errors,
          oneOfRequired: "At least one regulator or governing body is required",
        });
        return;
      }
      const newRegulators = values.regulators.filter(
        (reg) => !initialRegulators.some((initial) => initial.id === reg.id)
      );
      const newGoverningBodies = values.governingBodies.filter(
        (gb) => !initialGoverningBodies.some((initial) => initial.id === gb.id)
      );
      let data = {
        title: values.title,
        description: values.reportDescription,
        reportID: parseInt(id),
        reportType: values.reportType.name.toLowerCase(),
        deletedAttachments, 
      };

      if (newRegulators.length > 0 || newGoverningBodies.length > 0) {
        data.receivers = [
          ...newRegulators?.map((reg) => reg.id),
          ...newGoverningBodies?.map((gb) => gb.id),
        ];
      }
      let sportName = ''
      if (data?.reportType !== 'summary') {
        data.sportID = values.sport;
        const selectedSport = sports.find(sport => sport.value === values.sport);
        sportName = selectedSport ? selectedSport.label : "";
      }
      navigate("/preview-report", {state: {reportData: data, attachments: values.attachments , sportName}});
    },
  });

  const handleFileSelect = (event) => {

    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
    const currentAttachments = formik.values.attachments || [];
    const newAttachment = event.files[0];
    const duplicateFiles = (
      currentAttachments.some(existingFile => existingFile.name === newAttachment.name) ||
      attachments.some(existingFile => existingFile.name === newAttachment.name)
    )  
    if (duplicateFiles) {
      showToast("warn", "File names must be unique. Please rename your file(s) before uploading.");
      fileUploadRef.current.clear();
    } else if (currentAttachments?.length + 1 + attachments?.length > maxNumberOfFiles) {
      showToast("warn", `You can upload a maximum of ${maxNumberOfFiles} files (including existing and new files).`);
      fileUploadRef.current.clear();
    } else {
      const updatedAttachments = [...currentAttachments, newAttachment];
      formik.setFieldValue("attachments", updatedAttachments);
    }
  };
  
  const handleDeleteFile = (index) => {
    const updatedAttachments = [...formik.values.attachments];
    updatedAttachments.splice(index, 1);
    formik.setFieldValue("attachments", updatedAttachments);
  };

  useEffect(() => {
    getReportById(id, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        if(responseData?.length){
          setReportDetail(responseData[0])
        } else {
          navigate('/reports');
        }        
        setLoading(false);
      } else {
        navigate('/reports');
      }
    });
  }, [clearError, dispatch, headers, id, navigate, showToast]);

  useEffect(() => {
    if (reportDetail?.reportID) {
      const sportId =
        sports?.find((sport) => sport.label === reportDetail.sportName)
          ?.value || "";
      const reportType =
        typeFilter?.find((type) => type.name === reportDetail.reportType) ||
        null;
      const governingBodyOptions = governingBodies.map(g => g.id);
      const regulatorOptions = regulators.map(r => r.id);
      const savedGoverningBody = reportDetail.receivers?.filter(receiver => receiver.role === "governingbody" &&
        governingBodyOptions.includes(receiver.id));
      const savedRegulators = reportDetail.receivers?.filter(receiver => receiver.role === "regulator" &&
        regulatorOptions.includes(receiver.id));
      setInitialGoverningBodies(savedGoverningBody);
      setInitialRegulators(savedRegulators);
      setInitialValues({
        title: reportDetail.title || "",
        reportDescription: reportDetail?.description ? reportDetail?.description : '',
        reportType: reportType,
        sport: sportId,
        regulators: savedRegulators,
        governingBodies: savedGoverningBody,
        attachments: [],
      });

      if (reportDetail.fileAttachment) {
        const attachmentsArray = JSON.parse(reportDetail.fileAttachment);
        if (attachmentsArray && attachmentsArray.length > 0) {
          const attachmentPromises = attachmentsArray?.map((attachment) =>
            getFileDetails(attachment.key, headers, dispatch)
          );
          Promise.all(attachmentPromises).then((attachmentResponses) => {
            const attachmentData = attachmentResponses?.map(
              (attachmentResponse, index) => ({
                name: attachmentsArray?.length > 0 && attachmentsArray[index].name,
                data: attachmentResponse?.data,
                key: attachmentsArray?.length > 0 && attachmentsArray[index].key,
              })
            );
            setAttachments(attachmentData);
          });
        }
      }

    }
  }, [dispatch, governingBodies, headers, regulators, reportDetail, sports])

  useEffect(() => {
    getGroupsWithPermission(roleIds.regulator, permission?.reports,  headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const result = responseData?.length > 0 ? responseData : [];
        setRegulators(result);
      } else {
        const error = response.error;
        showToast(error.severity, error?.errorMsg || error?.summary);
      }
    });

    getGroupsWithPermission(roleIds?.governingbody,permission?.reports, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const result = responseData?.length > 0 ? responseData : [];
        setGoverningBodies(result);
      } else {
        const error = response.error;
        showToast(error.severity, error?.errorMsg || error?.summary);
      }
    });

    getSports(null, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const sports = response.data?.map((sport) => ({
          label: sport.name,
          value: sport.id,
        }));
        setSports(sports?.length > 0 ? sports?.sort((a, b) => a.label.localeCompare(b.label)) : []);
        if (sports.length === 0) {
          showToast("warn", "No records found");
        }
      } else {
        setSports([]);
        const error = response.error;
        showToast(error.severity, error.errorMsg || error.summary);
      }
    });
  }, [dispatch, headers, showToast]);

  const disableSelectedOptions = (options, selectedValues) => {
    return options?.map((option) => ({
      ...option,
      disabled: selectedValues.includes(option.value),
    }))?.sort((a, b) => a.label.localeCompare(b.label));
  };

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
      </span>
    );
  };

  const header = renderHeader();

  const downloadAttachment = (fileData, fileName) => {
    const link = document.createElement("a");
    link.href = fileData;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteExistingFile = (index) => {
    const updatedAttachments = [...attachments];
    const deletedAttachment = updatedAttachments.splice(index, 1)[0];
    setAttachments(updatedAttachments);
    setDeletedAttachments([
      ...deletedAttachments,
      {
        type: "application/pdf", 
        key: deletedAttachment.key, 
      },
    ]);
  };



  return (
    <Layout backBtn={"reports"} backBtnLabel="Reports">
      <section className="news-tab news-details-section general-news-section">
        { loading && 
        <div className="loading">
          <i className="pi pi-spin pi-spinner"></i>
        </div> }
        <div className="flex align-items-center justify-content-between mb-4">
          <div className="left">
            <h1>Edit Report</h1>
          </div>
          <div>
          <ErrorMessage id={`report_api_error`} />
          </div>
          <div className="right">
            <PR.Button
              label="Update & Preview"
              className="action-buttons save-button"
              disabled={!formik.dirty&&!deletedAttachments.length}
              onClick={formik.handleSubmit}
              type="submit"
            />
          </div>
        </div>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <div className="grid">
            <div className="col-12">
              <div className="new-list-section">
                <div className="list-wrapper">
                  <div className="news-list edit-details">
                    <div className="card">
                      <div className="col-12 p-0 mb-4">
                        <div className="flex flex-column form-input gap-2">
                          <label htmlFor="title">
                            Report title <span className="text-red">*</span>
                          </label>
                          <PR.InputText
                            id="title"
                            name="title"
                            type="text"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`input-field ${
                              formik.touched.title && formik.errors.title
                                ? "p-invalid"
                                : ""
                            }`}
                          />
                          {formik.errors.title && formik.touched.title && (
                            <div className="text-red">
                              {formik.errors.title}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 p-0">
                        <div className="flex flex-column gap-2">
                          <span className="label">Attach Files</span>
                          <div className="card create-file-upload flex align-items-center">
                            <PR.FileUpload
                              auto
                              mode="advanced"
                              name="attachments"
                              accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              chooseLabel="Choose Files"
                              multiple
                              ref={fileUploadRef}
                              onSelect={handleFileSelect}
                              onFocus={() => formik.setFieldTouched("attachments")}
                              customUpload
                            />
                            <span className="ml-2">
                              (Add up to {maxNumberOfFiles} files less than {fileUploadLimit.label} each)
                            </span>
                          </div>
                          {formik.values.attachments && (
                            <div className="uploaded-file-container flex flex-column gap-2">
                              {formik.values.attachments.map((attachment, index) => (
                                <div className="uploaded-files" key={index}>
                                  {attachment.name}
                                  <i
                                    className="pi pi-trash"
                                    onClick={() => handleDeleteFile(index)}
                                    style={{ cursor: 'pointer', marginLeft: '10px' }}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                          {formik.errors.attachments &&
                            (formik.touched.attachments || formik.submitCount > 0) && (
                              <div className="error-message" style={{ color: "red" }}>
                                {Array.isArray(formik.errors.attachments) ? (
                                  Array.from(new Set(formik.errors.attachments))?.map(
                                    (error, index) => (
                                      <div key={index}>{error}</div>
                                    )
                                  )
                                ) : (
                                  <div>{formik.errors.attachments}</div>
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                      {attachments.length > 0 && (
                        <div className="col-12 p-0 mt-2">
                          <div className="flex align-items-center gap-2">
                            {attachments?.map((attachment, index) => (
                              <div
                                key={index}
                                className="attachment-item flex align-items-center"
                              >
                                <PR.Button
                                  type="button"
                                  icon="pi pi-book"
                                  label={attachment.name}
                                  className="attachment-button"
                                  onClick={() =>
                                    downloadAttachment(
                                      attachment.data,
                                      attachment.name
                                    )
                                  }
                                />
                                <i
                                  className="pi pi-trash delete-attachment-icon"
                                  onClick={() => handleDeleteExistingFile(index)}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      <div className="col-12 p-0 mt-4">
                        <div className="flex flex-column gap-2">
                          <span className="label">
                            Report description{" "}
                            <span className="text-red">*</span>
                          </span>
                          <div className="card content-editor">
                            <PR.Editor
                             id="reportDescription"
                              value={formik.values.reportDescription}
                              name="reportDescription"
                              onTextChange={(e) => {
                                formik.setFieldValue(
                                  "reportDescription",
                                  e.htmlValue || ""
                                );
                              }}
                              headerTemplate={header}
                              style={{ height: "450px" }}
                            />
                          </div>
                          {formik.errors.reportDescription && (
                            <div className="text-red">
                              {formik.errors.reportDescription}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="grid mt-4">
                        <div className="col-6">
                          <div className="flex flex-column form-input gap-2">
                            <label htmlFor="reportType">
                              Report type
                            </label>
                            <PR.Dropdown
                              inputId="reportType"
                              value={formik.values.reportType}
                              onChange={(e) =>
                                formik.setFieldValue("reportType", e.value)
                              }
                              options={typeFilter}
                              optionLabel="name"
                              name="reportType"
                              placeholder="Select Report type"
                              className={`w-full ${
                                formik.errors.reportType &&
                                formik.touched.reportType
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled
                            />
                            {formik.errors.reportType &&
                              formik.touched.reportType && (
                                <div className="text-red">
                                  {formik.errors.reportType}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-6">
                          {formik.values?.reportType?.name?.toLowerCase() !== 'summary' &&
                            <div className="flex flex-column form-input gap-2">
                              <label htmlFor="sport">
                                Sport <span className="text-red">*</span>
                              </label>
                              <PR.Dropdown
                                inputId="sport"
                                value={formik.values.sport}
                                onChange={(e) =>
                                  formik.setFieldValue("sport", e.value)
                                }
                                filter
                                resetFilterOnHide
                                name="sport"
                                options={sports}
                                optionLabel="label"
                                placeholder="Select sport"
                                className="w-full"
                              />
                              {formik.errors.sport && formik.touched.sport && (
                                <div className="text-red">
                                  {formik.errors.sport}
                                </div>
                              )}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="grid mt-4">
                        <div className="col-12 pt-0 pb-0">
                          <label htmlFor="regulators">
                            Publish and Send by email to{" "}
                            <span className="text-red">*</span>
                          </label>
                        </div>
                        <div className="col-6">
                          <div className="flex flex-column form-input gap-2">
                            <PR.MultiSelect
                            inputId="regulators"
                              value={formik.values.regulators?.map(
                                (reg) => reg.id
                              )}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "regulators",
                                  e.value?.map((id) =>{
                                    const v = regulators.find((reg) => reg.id === id)
                                    return {id: v.id, name:v.name}
                                  }
                                  )
                                );
                              }}
                              options={disableSelectedOptions(
                                regulators?.map((reg) => ({
                                  label: reg.name,
                                  value: reg.id,
                                })),
                                initialRegulators?.map((reg) => reg.id)
                              )}
                              optionLabel="label"
                              filter
                              resetFilterOnHide
                              placeholder="Regulators"
                              maxSelectedLabels={3}
                              className="w-full"
                              showSelectAll
                              pt={{
                                  checkboxContainer: {
                                      onClick: (e) => {
                                          e.stopPropagation();
                                          const parent = e.target?.parentNode;
                                          if (parent && typeof parent.click === 'function') {
                                              parent.click();
                                          }
                                      },
                                  },
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="flex flex-column form-input gap-2">
                            <PR.MultiSelect
                              inputId="governingBodies"
                              value={formik.values.governingBodies?.map(
                                (body) => body.id
                              )}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "governingBodies",
                                  e.value?.map((id) =>
                                    {
                                      const v = governingBodies.find((body) => body.id === id);
                                      return {id: v.id, name:v.name}
                                    }
                                  )
                                );
                              }}
                              options={disableSelectedOptions(
                                governingBodies?.map((body) => ({
                                  label: body.name,
                                  value: body.id,
                                })),
                                initialGoverningBodies?.map((body) => body.id)
                              )}
                              optionLabel="label"
                              filter
                              resetFilterOnHide
                              placeholder="Sport Governing Bodies"
                              maxSelectedLabels={3}
                              className="w-full"
                              showSelectAll
                              pt={{
                                  checkboxContainer: {
                                      onClick: (e) => {
                                          e.stopPropagation();
                                          const parent = e.target?.parentNode;
                                          if (parent && typeof parent.click === 'function') {
                                              parent.click();
                                          }
                                      },
                                  },
                              }}
                            />
                          </div>
                        </div>
                        {formik.errors.oneOfRequired &&
                          (formik.touched.regulators ||
                            formik.touched.governingBodies) && (
                            <div className="col-12 text-red mt-2">
                              {formik.errors.oneOfRequired}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-right mt-4">
            <PR.Button
              label="Update & Preview"
              className="action-buttons save-button"
              disabled={!formik.dirty&&!deletedAttachments.length}
              type="submit"
            />
          </div>
        </form>
      </section>
    </Layout>
  );
};

export default EditReport;
