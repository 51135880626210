
// **** Main CSS **** //
import '../src/assets/scss/main.scss'

import Navigation from './navigation';

function App() {

  return (
        <Navigation></Navigation>
  );
}
export default App;
