import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../../layouts/Layout";
import * as PR from "../../../../prime-react/index";
import {
  getNewsDetails,
  getFileDetails,
  deleteNews,
} from "../../../../services/api";
import "./GeneralNewsDetails.scss";
import { newsActions } from "../../../../store/news";
import { DateFormat } from "../../../../utils/reuse";
import { ErrorMessage, useToast } from "../../../../context/ToastContext";

const deleteNewsError = 'general_delete_news_error'

const GeneralNewsDetails = () => {
  const { showToast, clearError } = useToast();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get("id");
  const [newsType, setnewsType] = useState('');
  const [newsDetail, setNewsDetail] = useState({});
  const dispatch = useDispatch();
  const [attachmentImage, setAttachmentImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const sessionData = useSelector((state) => state.auth.sessionData);
  const headers = useMemo(
    () => ({ sessionid: sessionData.sessionId }),
    [sessionData.sessionId]
  );

  useEffect(() => {
    if (id && headers) {
      setLoading(true);
      getNewsDetails(id, headers, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          const responseData = response.data?.length ?  response.data : [];
          const newsData = responseData.map((news) => ({
            ...news,
            fileAttachments: news?.fileAttachments
              ? JSON.parse(news.fileAttachments)
              : [],
          }));
          if(newsData?.length){
            setNewsDetail(newsData[0]);
            setnewsType(newsData[0]?.newsType);
          } else {
            navigate('/news')
          }
          setLoading(false);

        } else {
          setLoading(false);
          navigate('/news')
        }
      });
    }
  }, [id, headers, dispatch, showToast, navigate]);

  const [fileUrls, setFileUrls] = useState([]);

  useEffect(() => {
    if (newsDetail?.fileAttachments?.length > 0) {
      const attachments = newsDetail.fileAttachments;

      attachments.forEach((attachment) => {
        getFileDetails(attachment.key, headers, dispatch, (response) => {
          if (response.result === "SUCCESS") {
            const url = URL.createObjectURL(response.data);
            if (
              attachment.attachmentType === "coverImage"
            ) {
              setAttachmentImage(url);
            } else {
              setFileUrls((prev) => [
                ...prev,
                { url, name: attachment.name, key: attachment.key },
              ]);
            }
          } else {
            const error = response?.error;
            showToast(error.severity, error?.errorMsg || error?.summary);
          }
        });
      });
    }
  }, [newsDetail?.fileAttachments, headers, dispatch, showToast]);

  useEffect(() => {
    switch(newsType){
      case "sportingsanctions":
        dispatch(newsActions.updateTab({activeIndex:2}))
        return;
      case"integritypreviews":
        dispatch(newsActions.updateTab({activeIndex:1}))
        return;
      default:
        dispatch(newsActions.updateTab({activeIndex:0}))
        return;
    }
  },[newsType, dispatch])

  const handleDownload = (url, name) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((err) => {
        showToast("error", "Failed to download file");
      });
  };



  const handleDeleteNews = () => {
    clearError(deleteNewsError)
    setLoading(true);
    deleteNews(id, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        navigate("/news");
      } else {
        const error = response.error;
        showToast(error.severity, error?.errorMsg || error?.summary, deleteNewsError);
      }
      setLoading(false);
    });
  };

  if (loading) {
    return (
      <div className="loading">
        <i className="pi pi-spin pi-spinner"></i>
      </div>
    );
  }
  const isSportingSanction = newsType === "sportingsanctions";
  const isIntegrityNews = newsType === "integritypreviews";

  return (
    <Layout backBtn={"news"} backBtnLabel="News">
      <section className="news-tab news-details-section general-news-section">
        <div className="flex align-items-center justify-content-between mb-4">
          <div className="left">
            <h1 className="title">{newsDetail?.title}</h1>
          </div>
          <div className="right flex gap-2">
            <PR.Button
              label="Edit"
              icon="pi pi-pencil"
              className="action-buttons"
              onClick={() =>
                navigate(`/edit-details?id=${id}`)
              }
            />
            <PR.Button
              label="Delete"
              icon="pi pi-trash"
              className="action-buttons"
              onClick={() => setShowDeleteDialog(true)}
            />
          </div>
        </div>
        <div className="grid">
          <div className="col-12">
            <div className="new-list-section">
              <div className="list-wrapper">
                <div className="news-list">
                  <div className="card">
                    <div className="col-12 p-0">
                      <div className="flex align-items-center justify-content-between">
                        <ul className="flex list-none align-items-center gap-4">
                          {isSportingSanction ? (
                            <ul className="chips-ul gap-3">
                              {newsDetail?.keywords &&
                                newsDetail?.keywords
                                  ?.split(",")
                                  ?.map((keyword, index) => <li key={index}>{keyword}</li>)}
                            </ul>
                          ) : isIntegrityNews ? (
                            <li>
                              <i className="pi pi-user"></i>{" "}
                              <span className="author">
                                {newsDetail?.createdByUser}
                              </span>
                            </li>
                          ) : (
                            <>
                              <li>
                                <i className="pi pi-user"></i>{" "}
                                <span className="author">
                                  {newsDetail?.createdByUser}
                                </span>
                              </li>
                              <li>
                                <i className="pi pi-calendar"></i>{" "}
                                <span>
                                { newsDetail?.createdAt  && DateFormat(newsDetail?.createdAt)}
                                </span>
                              </li>
                            </>
                          )}
                        </ul>
                        <ul className="flex list-none align-items-center gap-4">
                          {isSportingSanction ? (
                            <li>
                              <i className="pi pi-calendar"></i>{" "}
                              <span>
                                {DateFormat(newsDetail?.createdAt)}
                              </span>
                            </li>
                          ) : isIntegrityNews ? (
                            <li>
                              <i className="pi pi-calendar"></i>{" "}
                              <span>
                                {DateFormat(newsDetail?.createdAt)}
                              </span>
                            </li>
                          ) : (
                            <li>
                              <i className="pi pi-book"></i>{" "}
                              <span>{newsDetail?.categoryName}</span>
                            </li>
                          )}
                        </ul>
                      </div>
                      {attachmentImage && (
                        <PR.Image
                          src={attachmentImage}
                          alt={newsDetail?.title}
                          className="news-img mb-4"
                        />
                      )}
                    </div>
                    <div className="col-12 p-0">
                      <div className="content">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: newsDetail?.description,
                          }}
                        />
                      </div>
                      {fileUrls && fileUrls?.length > 0 && (
                        <div className="attachments">
                          <span className="heading-span">Attached Files:</span>
                          {fileUrls?.map((file, index) => (
                            <PR.Button
                              key={file.key}
                              icon="pi pi-book"
                              label={file.name}
                              className="download-button hide-on-download"
                              onClick={() =>
                                handleDownload(file.url, file.name)
                              }
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PR.Dialog
        visible={showDeleteDialog}
        className="news-dialog"
        header="Delete news"
        modal
        closable={false}
        footer={
          <>
            <hr className="ruler" />
            <ErrorMessage id={deleteNewsError} />
            <PR.Button
              label="Cancel"
              className="p-button-text custom-cancel-button"
              onClick={() => { clearError(deleteNewsError); setShowDeleteDialog(false) }}
            />
            <PR.Button
              label="Delete"
              className="p-button-danger custom-delete-button"
              onClick={handleDeleteNews}
            />
          </>
        }
      >
        <div className="confirmation-content">
          <span>Are you sure you want to delete this news?</span>
        </div>
      </PR.Dialog>

    </Layout>
  );
};

export default GeneralNewsDetails;
