import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const BackMenuLayout = ({children}) => {
    return (
        <>
            <div className="main">
                <Header/>
                    {children}
                <Footer/>
            </div>
        </>
    );
};

export default BackMenuLayout;